import { useRef } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { useSliderThumb } from '@react-aria/slider';
import { mergeProps } from '@react-aria/utils';
import { SliderState } from '@react-stately/slider';

import { VisuallyHidden, styled } from '@agentero/styles/jsx';

type ThumbProps = {
	state: SliderState;
	trackRef: React.RefObject<Element>;
	index: number;
	name?: string;
};

const ThumbButton = styled('div', {
	base: {
		width: '20',
		height: '20',
		top: '50%',
		background: 'white',
		border: '2px solid token(colors.neutral)',
		borderRadius: 'full'
	}
});

export const Thumb = (props: ThumbProps) => {
	const { state, trackRef, index, name } = props;
	const inputRef = useRef(null!);
	const { thumbProps, inputProps, isDragging } = useSliderThumb(
		{
			index,
			trackRef,
			inputRef,
			name
		},
		state
	);

	const { focusProps, isFocusVisible } = useFocusRing();
	return (
		<ThumbButton
			{...thumbProps}
			className={`thumb ${isFocusVisible ? 'focus' : ''} ${isDragging ? 'dragging' : ''}`}>
			<VisuallyHidden>
				<input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
			</VisuallyHidden>
		</ThumbButton>
	);
};
