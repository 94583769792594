// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/license/license.proto (package agentero.license, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/license/license.proto.
 */
export const file_agentero_license_license: GenFile = /*@__PURE__*/
  fileDesc("Ch5hZ2VudGVyby9saWNlbnNlL2xpY2Vuc2UucHJvdG8SEGFnZW50ZXJvLmxpY2Vuc2UibAoKTGljZW5zZUxPQRIuCgNMT0EYASABKA4yIS5hZ2VudGVyby5saWNlbnNlLkxpbmVPZkF1dGhvcml0eRIuCgppc3N1ZV9kYXRlGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcCLcAgoHTGljZW5zZRIWCg5saWNlbnNlX251bWJlchgBIAEoCRIVCg1saWNlbnNlX3N0YXRlGAIgASgJEjMKD2V4cGlyYXRpb25fZGF0ZRgDIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASGAoQcmVzaWRlbmN5X3N0YXR1cxgEIAEoCRIOCgZhY3RpdmUYBSABKAgSLgoKdXBkYXRlZF9hdBgGIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASLwoGc3RhdHVzGAcgASgOMh8uYWdlbnRlcm8ubGljZW5zZS5MaWNlbnNlU3RhdHVzEi4KCmlzc3VlX2RhdGUYCCABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wEjIKDGxpY2Vuc2VfbG9hcxgJIAMoCzIcLmFnZW50ZXJvLmxpY2Vuc2UuTGljZW5zZUxPQSqNAwoPTGluZU9mQXV0aG9yaXR5Eh8KG0xJTkVfT0ZfQVVUSE9SSVRZX1VOREVGSU5FRBAAEhoKFkxJTkVfT0ZfQVVUSE9SSVRZX0xJRkUQARIeChpMSU5FX09GX0FVVEhPUklUWV9QRVJTT05BTBACEiMKH0xJTkVfT0ZfQVVUSE9SSVRZX1BFUlNPTkFMX0FVVE8QAxIrCidMSU5FX09GX0FVVEhPUklUWV9QUk9QRVJUWV9BTkRfQ0FTVUFMVFkQBBIcChhMSU5FX09GX0FVVEhPUklUWV9IRUFMVEgQBRIgChxMSU5FX09GX0FVVEhPUklUWV9DT01NRVJDSUFMEAYSKAokTElORV9PRl9BVVRIT1JJVFlfTElNSVRFRF9DT01NRVJDSUFMEAcSIwofTElORV9PRl9BVVRIT1JJVFlfU1VSUExVU19MSU5FUxAIEhsKF0xJTkVfT0ZfQVVUSE9SSVRZX09USEVSEAkSHwobTElORV9PRl9BVVRIT1JJVFlfUFJJTkNJUEFMEAoqqgEKDUxpY2Vuc2VTdGF0dXMSHgoaTElDRU5TRV9TVEFUVVNfVU5TUEVDSUZJRUQQABIaChZMSUNFTlNFX1NUQVRVU19FWFBJUkVEEAESGAoUTElDRU5TRV9TVEFUVVNfVkFMSUQQAhIdChlMSUNFTlNFX1NUQVRVU19OT1RfQUNUSVZFEAMSJAogTElDRU5TRV9TVEFUVVNfTE9BX05PVF9TVVBQT1JURUQQBCqlAQoTTGljZW5zZUdsb2JhbFN0YXR1cxIlCiFMSUNFTlNFX0dMT0JBTF9TVEFUVVNfVU5TUEVDSUZJRUQQABIjCh9MSUNFTlNFX0dMT0JBTF9TVEFUVVNfTk9UX0ZPVU5EEAESHwobTElDRU5TRV9HTE9CQUxfU1RBVFVTX1ZBTElEEAISIQodTElDRU5TRV9HTE9CQUxfU1RBVFVTX0lOVkFMSUQQA2IGcHJvdG8z", [file_google_protobuf_timestamp]);

/**
 * @generated from message agentero.license.LicenseLOA
 */
export type LicenseLOA = Message<"agentero.license.LicenseLOA"> & {
  /**
   * @generated from field: agentero.license.LineOfAuthority LOA = 1;
   */
  LOA: LineOfAuthority;

  /**
   * @generated from field: google.protobuf.Timestamp issue_date = 2;
   */
  issueDate?: Timestamp;
};

/**
 * Describes the message agentero.license.LicenseLOA.
 * Use `create(LicenseLOASchema)` to create a new message.
 */
export const LicenseLOASchema: GenMessage<LicenseLOA> = /*@__PURE__*/
  messageDesc(file_agentero_license_license, 0);

/**
 * @generated from message agentero.license.License
 */
export type License = Message<"agentero.license.License"> & {
  /**
   * @generated from field: string license_number = 1;
   */
  licenseNumber: string;

  /**
   * @generated from field: string license_state = 2;
   */
  licenseState: string;

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 3;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from field: string residency_status = 4;
   */
  residencyStatus: string;

  /**
   * @generated from field: bool active = 5;
   */
  active: boolean;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: agentero.license.LicenseStatus status = 7;
   */
  status: LicenseStatus;

  /**
   * @generated from field: google.protobuf.Timestamp issue_date = 8;
   */
  issueDate?: Timestamp;

  /**
   * @generated from field: repeated agentero.license.LicenseLOA license_loas = 9;
   */
  licenseLoas: LicenseLOA[];
};

/**
 * Describes the message agentero.license.License.
 * Use `create(LicenseSchema)` to create a new message.
 */
export const LicenseSchema: GenMessage<License> = /*@__PURE__*/
  messageDesc(file_agentero_license_license, 1);

/**
 * @generated from enum agentero.license.LineOfAuthority
 */
export enum LineOfAuthority {
  /**
   * @generated from enum value: LINE_OF_AUTHORITY_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_LIFE = 1;
   */
  LIFE = 1,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PERSONAL = 2;
   */
  PERSONAL = 2,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PERSONAL_AUTO = 3;
   */
  PERSONAL_AUTO = 3,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PROPERTY_AND_CASUALTY = 4;
   */
  PROPERTY_AND_CASUALTY = 4,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_HEALTH = 5;
   */
  HEALTH = 5,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_COMMERCIAL = 6;
   */
  COMMERCIAL = 6,

  /**
   * Limited Commercial is only used in E&O
   *
   * @generated from enum value: LINE_OF_AUTHORITY_LIMITED_COMMERCIAL = 7;
   */
  LIMITED_COMMERCIAL = 7,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_SURPLUS_LINES = 8;
   */
  SURPLUS_LINES = 8,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_OTHER = 9;
   */
  OTHER = 9,

  /**
   * @generated from enum value: LINE_OF_AUTHORITY_PRINCIPAL = 10;
   */
  PRINCIPAL = 10,
}

/**
 * Describes the enum agentero.license.LineOfAuthority.
 */
export const LineOfAuthoritySchema: GenEnum<LineOfAuthority> = /*@__PURE__*/
  enumDesc(file_agentero_license_license, 0);

/**
 * @generated from enum agentero.license.LicenseStatus
 */
export enum LicenseStatus {
  /**
   * @generated from enum value: LICENSE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LICENSE_STATUS_EXPIRED = 1;
   */
  EXPIRED = 1,

  /**
   * @generated from enum value: LICENSE_STATUS_VALID = 2;
   */
  VALID = 2,

  /**
   * @generated from enum value: LICENSE_STATUS_NOT_ACTIVE = 3;
   */
  NOT_ACTIVE = 3,

  /**
   * @generated from enum value: LICENSE_STATUS_LOA_NOT_SUPPORTED = 4;
   */
  LOA_NOT_SUPPORTED = 4,
}

/**
 * Describes the enum agentero.license.LicenseStatus.
 */
export const LicenseStatusSchema: GenEnum<LicenseStatus> = /*@__PURE__*/
  enumDesc(file_agentero_license_license, 1);

/**
 * LicenseGlobalStatus is the license global status
 *
 * @generated from enum agentero.license.LicenseGlobalStatus
 */
export enum LicenseGlobalStatus {
  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_NOT_FOUND = 1;
   */
  NOT_FOUND = 1,

  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_VALID = 2;
   */
  VALID = 2,

  /**
   * @generated from enum value: LICENSE_GLOBAL_STATUS_INVALID = 3;
   */
  INVALID = 3,
}

/**
 * Describes the enum agentero.license.LicenseGlobalStatus.
 */
export const LicenseGlobalStatusSchema: GenEnum<LicenseGlobalStatus> = /*@__PURE__*/
  enumDesc(file_agentero_license_license, 2);

