import { PropsWithChildren } from 'react';

import { RecipeVariantProps, cva } from '@agentero/styles/css';

// TODO: Review styles and variants when NEXT_PUBLIC_REDESIGN_FEATURE flag is removed. Consider using a slot recipe for all data table components.
const dataTableLoadingOverlayStyles = cva({
	base: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		minHeight: '0',
		transition: 'opacity 0.15s'
	},
	variants: {
		isLoading: {
			true: {
				opacity: '0.5'
			}
		}
	},
	defaultVariants: {
		isLoading: false
	}
});

type DataTableLoadingOverlayProps = RecipeVariantProps<typeof dataTableLoadingOverlayStyles>;

export const DataTableLoadingOverlay = ({
	children,
	...props
}: PropsWithChildren<DataTableLoadingOverlayProps>) => (
	<div data-slot="data-table-loading-overlay" className={dataTableLoadingOverlayStyles(props)}>
		{children}
	</div>
);
