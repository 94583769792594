import { createClient } from '@connectrpc/connect';

import { ComplianceService } from '@agentero/service-clients/portal/compliance';
import { LeadService } from '@agentero/service-clients/portal/lead';
import { ProducerIDService } from '@agentero/service-clients/portal/producerid';
import { QuoteService } from '@agentero/service-clients/portal/quote';
import { RaterService } from '@agentero/service-clients/portal/rater';
import { SessionService } from '@agentero/service-clients/portal/session';
import { VehicleService } from '@agentero/service-clients/portal/vehicle';

import { portalTransport } from './connectClients/portalTransport';

export const leadService = createClient(LeadService, portalTransport);
export const raterService = createClient(RaterService, portalTransport);
export const portalService = createClient(SessionService, portalTransport);
export const complianceService = createClient(ComplianceService, portalTransport);
export const vehicleService = createClient(VehicleService, portalTransport);
export const quoteService = createClient(QuoteService, portalTransport);
export const producerIdService = createClient(ProducerIDService, portalTransport);
