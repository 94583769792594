import { HTMLAttributes, PropsWithChildren } from 'react';

import { cva } from '@agentero/styles/css';

const tableRowStyles = cva({
	base: {
		transition: 'background-color 0.15s',
		_hover: {
			'& [data-slot="table-row-actions"] [data-slot="button"]': {
				opacity: 1
			}
		}
	}
});

export const TableRow = ({
	children,
	...props
}: PropsWithChildren<HTMLAttributes<HTMLTableRowElement>>) => (
	<tr data-slot="table-row" className={tableRowStyles()} {...props}>
		{children}
	</tr>
);
