import { DOMAttributes, PropsWithChildren, createContext, useContext, useRef } from 'react';

import { AriaDialogProps, useDialog } from '@react-aria/dialog';
import { FocusableElement } from '@react-types/shared';

import { Box } from '@agentero/styles/jsx';
import { ShadowToken } from '@agentero/styles/tokens';

type DialogProps = AriaDialogProps & {
	boxShadow?: ShadowToken;
};

type DialogContextValue = {
	titleProps: DOMAttributes<FocusableElement>;
};

export const useAgDialog = () => useContext(DialogContext);

const DialogContext = createContext<DialogContextValue>({
	titleProps: {}
});

export const Dialog = ({ children, boxShadow = '1', ...props }: PropsWithChildren<DialogProps>) => {
	const ref = useRef(null!);
	const { dialogProps, titleProps } = useDialog(
		{
			...props,
			role: 'alertdialog'
		},
		ref
	);

	return (
		<DialogContext.Provider value={{ titleProps }}>
			<Box {...dialogProps} ref={ref} outline="none" borderRadius="lg" boxShadow={boxShadow}>
				{children}
			</Box>
		</DialogContext.Provider>
	);
};
