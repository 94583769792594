import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

interface IDropdownItemGroup {
	groupText: string;
	isSecondary?: boolean;
}

const StyledDropdownTextGroup = styled.div`
	padding: 16px;
	padding: 16px 16px 8px;
	margin: -16px 0 0px;
	background: white;
	text-transform: uppercase;

	${({ theme }) => theme.mediaqueries.M} {
		position: sticky;
		top: 0;
	}
`;

const StyledDropdownItemGroup = styled.li<{ isSecondary: boolean }>`
	background: ${props => (props.isSecondary ? props.theme.colors.gray.lighten90 : 'transparent')};
	padding: 16px 0;
`;

export const DropdownItemGroup = ({
	groupText,
	children,
	isSecondary = false
}: PropsWithChildren<IDropdownItemGroup>) => (
	<StyledDropdownItemGroup isSecondary={isSecondary}>
		<StyledDropdownTextGroup>
			<b>{groupText}</b>
		</StyledDropdownTextGroup>
		<ul>{children}</ul>
	</StyledDropdownItemGroup>
);
