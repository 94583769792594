import { PropsWithChildren, useRef } from 'react';

import { AriaPopoverProps, DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import { OverlayTriggerState } from '@react-stately/overlays';

type PopoverProps = {
	state: OverlayTriggerState;
} & Omit<AriaPopoverProps, 'popoverRef'>;

export const DatepickerPopover = (props: PropsWithChildren<PopoverProps>) => {
	const ref = useRef(null!);
	const { state, children } = props;

	const { popoverProps, underlayProps } = usePopover(
		{
			...props,
			popoverRef: ref
		},
		state
	);

	return (
		<Overlay>
			<div {...underlayProps} />
			<div {...popoverProps} ref={ref}>
				<DismissButton onDismiss={state.close} />
				{children}
				<DismissButton onDismiss={state.close} />
			</div>
		</Overlay>
	);
};
