// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/contact/contact.proto (package agentero.contact, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/contact/contact.proto.
 */
export const file_agentero_contact_contact: GenFile = /*@__PURE__*/
  fileDesc("Ch5hZ2VudGVyby9jb250YWN0L2NvbnRhY3QucHJvdG8SEGFnZW50ZXJvLmNvbnRhY3QiVgoHQWRkcmVzcxIOCgZzdHJlZXQYASABKAkSEAoIemlwX2NvZGUYAiABKAkSDAoEY2l0eRgDIAEoCRINCgVzdGF0ZRgEIAEoCRIMCgR1bml0GAUgASgJKi8KD0NvbnRhY3RMaW5lVHlwZRIMCghQRVJTT05BTBAAEg4KCkNPTU1FUkNJQUwQAWIGcHJvdG8z");

/**
 * @generated from message agentero.contact.Address
 */
export type Address = Message<"agentero.contact.Address"> & {
  /**
   * @generated from field: string street = 1;
   */
  street: string;

  /**
   * @generated from field: string zip_code = 2;
   */
  zipCode: string;

  /**
   * @generated from field: string city = 3;
   */
  city: string;

  /**
   * @generated from field: string state = 4;
   */
  state: string;

  /**
   * @generated from field: string unit = 5;
   */
  unit: string;
};

/**
 * Describes the message agentero.contact.Address.
 * Use `create(AddressSchema)` to create a new message.
 */
export const AddressSchema: GenMessage<Address> = /*@__PURE__*/
  messageDesc(file_agentero_contact_contact, 0);

/**
 * @generated from enum agentero.contact.ContactLineType
 */
export enum ContactLineType {
  /**
   * @generated from enum value: PERSONAL = 0;
   */
  PERSONAL = 0,

  /**
   * @generated from enum value: COMMERCIAL = 1;
   */
  COMMERCIAL = 1,
}

/**
 * Describes the enum agentero.contact.ContactLineType.
 */
export const ContactLineTypeSchema: GenEnum<ContactLineType> = /*@__PURE__*/
  enumDesc(file_agentero_contact_contact, 0);

