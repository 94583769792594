import { PropsWithChildren, TdHTMLAttributes } from 'react';

import { cva } from '@agentero/styles/css';

const tableCellStyles = cva({
	base: {
		textStyle: 'body.small'
	}
});

export const TableCell = ({
	children,
	...props
}: PropsWithChildren<TdHTMLAttributes<HTMLTableCellElement>>) => (
	<td data-slot="table-cell" className={tableCellStyles()} {...props}>
		{children}
	</td>
);
