// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/producerid.proto (package agentero.portal, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/portal/producerid.proto.
 */
export const file_agentero_portal_producerid: GenFile = /*@__PURE__*/
  fileDesc("CiBhZ2VudGVyby9wb3J0YWwvcHJvZHVjZXJpZC5wcm90bxIPYWdlbnRlcm8ucG9ydGFsIhkKF0dldEFnZW5jeU9uYm9hcmRSZXF1ZXN0IscBChhHZXRBZ2VuY3lPbmJvYXJkUmVzcG9uc2USSwoMb25ib2FyZF9pbmZvGAEgASgLMjUuYWdlbnRlcm8ucG9ydGFsLkdldEFnZW5jeU9uYm9hcmRSZXNwb25zZS5PbmJvYXJkSW5mbxpeCgtPbmJvYXJkSW5mbxIRCglhZ2VuY3lfaWQYASABKAkSGwoTb25ib2FyZGVkX2FnZW5jeV9pZBgCIAEoCRIfChdwZGJfYWxlcnRzX3N5bmNfZW5hYmxlZBgDIAEoCCIWChRPbmJvYXJkQWdlbmN5UmVxdWVzdCIXChVPbmJvYXJkQWdlbmN5UmVzcG9uc2Uy3AEKEVByb2R1Y2VySURTZXJ2aWNlEmcKEEdldEFnZW5jeU9uYm9hcmQSKC5hZ2VudGVyby5wb3J0YWwuR2V0QWdlbmN5T25ib2FyZFJlcXVlc3QaKS5hZ2VudGVyby5wb3J0YWwuR2V0QWdlbmN5T25ib2FyZFJlc3BvbnNlEl4KDU9uYm9hcmRBZ2VuY3kSJS5hZ2VudGVyby5wb3J0YWwuT25ib2FyZEFnZW5jeVJlcXVlc3QaJi5hZ2VudGVyby5wb3J0YWwuT25ib2FyZEFnZW5jeVJlc3BvbnNlYgZwcm90bzM");

/**
 * @generated from message agentero.portal.GetAgencyOnboardRequest
 */
export type GetAgencyOnboardRequest = Message<"agentero.portal.GetAgencyOnboardRequest"> & {
};

/**
 * Describes the message agentero.portal.GetAgencyOnboardRequest.
 * Use `create(GetAgencyOnboardRequestSchema)` to create a new message.
 */
export const GetAgencyOnboardRequestSchema: GenMessage<GetAgencyOnboardRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_producerid, 0);

/**
 * @generated from message agentero.portal.GetAgencyOnboardResponse
 */
export type GetAgencyOnboardResponse = Message<"agentero.portal.GetAgencyOnboardResponse"> & {
  /**
   * @generated from field: agentero.portal.GetAgencyOnboardResponse.OnboardInfo onboard_info = 1;
   */
  onboardInfo?: GetAgencyOnboardResponse_OnboardInfo;
};

/**
 * Describes the message agentero.portal.GetAgencyOnboardResponse.
 * Use `create(GetAgencyOnboardResponseSchema)` to create a new message.
 */
export const GetAgencyOnboardResponseSchema: GenMessage<GetAgencyOnboardResponse> = /*@__PURE__*/
  messageDesc(file_agentero_portal_producerid, 1);

/**
 * @generated from message agentero.portal.GetAgencyOnboardResponse.OnboardInfo
 */
export type GetAgencyOnboardResponse_OnboardInfo = Message<"agentero.portal.GetAgencyOnboardResponse.OnboardInfo"> & {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId: string;

  /**
   * @generated from field: string onboarded_agency_id = 2;
   */
  onboardedAgencyId: string;

  /**
   * @generated from field: bool pdb_alerts_sync_enabled = 3;
   */
  pdbAlertsSyncEnabled: boolean;
};

/**
 * Describes the message agentero.portal.GetAgencyOnboardResponse.OnboardInfo.
 * Use `create(GetAgencyOnboardResponse_OnboardInfoSchema)` to create a new message.
 */
export const GetAgencyOnboardResponse_OnboardInfoSchema: GenMessage<GetAgencyOnboardResponse_OnboardInfo> = /*@__PURE__*/
  messageDesc(file_agentero_portal_producerid, 1, 0);

/**
 * @generated from message agentero.portal.OnboardAgencyRequest
 */
export type OnboardAgencyRequest = Message<"agentero.portal.OnboardAgencyRequest"> & {
};

/**
 * Describes the message agentero.portal.OnboardAgencyRequest.
 * Use `create(OnboardAgencyRequestSchema)` to create a new message.
 */
export const OnboardAgencyRequestSchema: GenMessage<OnboardAgencyRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_producerid, 2);

/**
 * @generated from message agentero.portal.OnboardAgencyResponse
 */
export type OnboardAgencyResponse = Message<"agentero.portal.OnboardAgencyResponse"> & {
};

/**
 * Describes the message agentero.portal.OnboardAgencyResponse.
 * Use `create(OnboardAgencyResponseSchema)` to create a new message.
 */
export const OnboardAgencyResponseSchema: GenMessage<OnboardAgencyResponse> = /*@__PURE__*/
  messageDesc(file_agentero_portal_producerid, 3);

/**
 * @generated from service agentero.portal.ProducerIDService
 */
export const ProducerIDService: GenService<{
  /**
   * @generated from rpc agentero.portal.ProducerIDService.GetAgencyOnboard
   */
  getAgencyOnboard: {
    methodKind: "unary";
    input: typeof GetAgencyOnboardRequestSchema;
    output: typeof GetAgencyOnboardResponseSchema;
  },
  /**
   * @generated from rpc agentero.portal.ProducerIDService.OnboardAgency
   */
  onboardAgency: {
    methodKind: "unary";
    input: typeof OnboardAgencyRequestSchema;
    output: typeof OnboardAgencyResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_agentero_portal_producerid, 0);

