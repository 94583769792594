import { useEffect, useReducer, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { Truncate } from '@agentero/components';
import { IconClose } from '@agentero/icons';
import { Button } from '@agentero/ui';

import {
	AppliedFiltersActions,
	AppliedFiltersActionsItem,
	AppliedFiltersButton,
	AppliedFiltersButtonIcon,
	AppliedFiltersButtons,
	AppliedFiltersContainer,
	AppliedFiltersWidth
} from './appliedFilters/AppliedFiltersStyled';
import { appliedFiltersReducer } from './appliedFilters/appliedFiltersReducer';

export type AppliedFilter = {
	key: string;
	label: string;
	value: unknown;
};

type AppliedFiltersProps = {
	appliedFilters: AppliedFilter[];
	onClear: () => void;
	onRemoveFilter: (filter: AppliedFilter) => void;
};

const initialAppliedFilters = {
	hasSpace: true,
	isAllFiltersVisible: false
};

export const AppliedFilters = ({
	appliedFilters,
	onClear,
	onRemoveFilter
}: AppliedFiltersProps) => {
	const { t } = useTranslation('appliedFilters');

	const [{ hasSpace, isAllFiltersVisible }, dispatch] = useReducer(
		appliedFiltersReducer,
		initialAppliedFilters
	);

	const parentRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
	const resetButtonRef = useRef<HTMLButtonElement>(null) as React.RefObject<HTMLButtonElement>;
	const widthRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;

	const handleSpace = () => {
		if (parentRef.current && widthRef.current && resetButtonRef.current) {
			const totalFiltersWidth = Array.prototype.slice
				.call(parentRef.current.children[0].children)
				.reduce(
					(total: number, element: HTMLElement) =>
						element.getBoundingClientRect().width + total + 8,
					0
				);

			const containerWidth = widthRef.current.getBoundingClientRect().width;
			const resetButtonWidth = resetButtonRef.current.getBoundingClientRect().width;

			if (totalFiltersWidth + resetButtonWidth + 8 > containerWidth) {
				dispatch({ type: 'SHOW_SHOW_MORE' });
			} else {
				dispatch({ type: 'HIDE_SHOW_MORE' });
			}
		}
	};

	useEffect(() => {
		handleSpace();
		window.addEventListener('resize', handleSpace);

		return () => window.removeEventListener('resize', handleSpace);
	}, [appliedFilters.length]);

	return (
		<>
			<AppliedFiltersWidth ref={widthRef} />
			<AppliedFiltersContainer
				isAllFiltersVisible={isAllFiltersVisible}
				hasFilters={appliedFilters.length > 0}
				ref={parentRef}>
				<AppliedFiltersButtons hasSpace={hasSpace}>
					{appliedFilters.map(filter => {
						return (
							<AppliedFiltersButton key={filter.label}>
								<Truncate>{filter.label}</Truncate>
								<AppliedFiltersButtonIcon onClick={() => onRemoveFilter(filter)}>
									<IconClose />
								</AppliedFiltersButtonIcon>
							</AppliedFiltersButton>
						);
					})}
				</AppliedFiltersButtons>
				{appliedFilters.length > 1 && (
					<AppliedFiltersActions isAllFiltersVisible={isAllFiltersVisible}>
						{!hasSpace && (
							<AppliedFiltersActionsItem>
								<Button
									variant="link"
									onClick={() => dispatch({ type: 'TOGGLE_ALL_FILTERS_VISIBILITY' })}>
									{t(`${isAllFiltersVisible ? 'lessFilters' : 'moreFilters'}`)}...
								</Button>
							</AppliedFiltersActionsItem>
						)}

						<AppliedFiltersActionsItem>
							<Button variant="link" onClick={onClear} ref={resetButtonRef}>
								{t('reset')}
							</Button>
						</AppliedFiltersActionsItem>
					</AppliedFiltersActions>
				)}
			</AppliedFiltersContainer>
		</>
	);
};
