import { Fragment } from 'react';

import {
	CellContext,
	ColumnDefTemplate,
	ColumnHelper,
	createColumnHelper
} from '@tanstack/react-table';

import { Box } from '@agentero/styles/jsx';
import { parseDateToMonthDayYear, parsePhoneNumber } from '@agentero/utils';

import { Text, TextSizeType } from '../Text';
import { TableRowActions } from '../table/TableRowActions';

type DataTableType = 'email' | 'date' | 'phone' | 'actions';

export type DataTableColumns<TData> = {
	accessor: Parameters<ColumnHelper<TData>['accessor']>[0];
	header: string;
	type?: DataTableType;
	cell?: ColumnDefTemplate<CellContext<TData, unknown>>;
	width?: string;
	minWidth?: string;
	align?: 'left' | 'center' | 'right';
	size?: TextSizeType;
	truncate?: boolean;
	hidden?: (props: CellContext<TData, unknown>) => boolean;
	enableSorting?: boolean;
	sortDescFirst?: boolean;
}[];

export const getDataTableColumns = <TData extends {}>(columns: DataTableColumns<TData>) => {
	const columnHelper = createColumnHelper<TData>();

	return columns.map(
		({
			accessor,
			header,
			cell,
			type,
			width,
			minWidth,
			size,
			truncate,
			align,
			enableSorting = false,
			sortDescFirst = false,
			hidden
		}) =>
			columnHelper.accessor(accessor, {
				header: header,
				enableSorting,
				sortDescFirst,
				cell: props => {
					const Cell = cell && typeof cell === 'function' ? cell(props) : cell;
					const Wrapper = type === 'actions' ? TableRowActions : Fragment;

					return (
						<Box
							display="inline-block"
							mixBlendMode="multiply"
							verticalAlign="top"
							style={{
								width: width ?? (type === 'actions' ? 'fit-content' : '10rem'),
								minWidth
							}}>
							{hidden && hidden(props) ? undefined : (
								<Text size={size ?? 'inherit'} truncate={truncate} align={align}>
									<Wrapper>
										{Cell ?? (
											<DataTableCell
												type={type}
												{...(props as CellContext<TData, string | Date>)}
											/>
										)}
									</Wrapper>
								</Text>
							)}
						</Box>
					);
				},
				meta: {
					style: {
						textAlign: align,
						width: width ?? (type === 'actions' ? '0.0625rem' : undefined)
					}
				}
			})
	);
};

type DataTableCellProps<TData, TValue> = CellContext<TData, TValue> & {
	type?: DataTableType;
};

const DataTableCell = <TData extends {}>(props: DataTableCellProps<TData, string | Date>) => {
	const value = props.getValue();
	return props.type ? (
		columnTypeMapping[props.type](props as CellContext<TData, string> & CellContext<TData, Date>)
	) : (
		<>{value}</>
	);
};

const DataTableCellEmail = <TData extends {}>({ getValue }: DataTableCellProps<TData, string>) => {
	const value = getValue();
	return <a href={`mailto:${value}`}>{value}</a>;
};

const DataTableCellPhone = <TData extends {}>({ getValue }: DataTableCellProps<TData, string>) => {
	const value = getValue();
	return <a href={`tel:${value}`}>{parsePhoneNumber(value)}</a>;
};

const DataTableCellDate = <TData extends {}>({ getValue }: DataTableCellProps<TData, Date>) => {
	const value = getValue();
	return value ? parseDateToMonthDayYear({ date: value, language: 'en-US' }) : '';
};

const columnTypeMapping = {
	email: DataTableCellEmail,
	phone: DataTableCellPhone,
	date: DataTableCellDate,
	actions: TableRowActions
};
