import { PropsWithChildren, useRef } from 'react';

import { AriaButtonProps, useButton } from '@react-aria/button';

import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

const Button = styled('button', {
	base: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		backgroundColor: 'white',
		color: 'neutral',
		borderRadius: 'none',
		outline: 'none',
		borderLeftStyle: 'solid',
		borderLeftWidth: '1px',
		borderLeftColor: 'neutral.80',
		_focus: {
			backgroundColor: 'neutral.90'
		},
		_disabled: {
			background: 'neutral.90',
			'&:hover': {
				backgroundColor: 'neutral.90'
			},
			'&:active': {
				backgroundColor: 'neutral.90'
			}
		},
		'&:hover': {
			backgroundColor: 'neutral.95'
		},
		'&:active': {
			backgroundColor: 'neutral.80'
		},
		'& svg': {
			width: '1.25rem',
			height: '1.25rem'
		}
	},
	variants: {
		size: {
			sm: {
				width: '24px',
				height: '24px',
				minWidth: '24px'
			},
			md: {
				width: '32px',
				height: '32px',
				minWidth: '32px'
			},
			lg: {
				width: '40px',
				height: '40px',
				minWidth: '40px'
			}
		}
	},
	defaultVariants: {
		size: 'md'
	}
});

type Variants = StyledVariantProps<typeof Button>;

type ButtonAriaProps = AriaButtonProps<'button'> &
	Variants & {
		isPressed?: boolean;
	};

export const DatePickerButton = (props: PropsWithChildren<ButtonAriaProps>) => {
	const { children } = props;
	const ref = useRef(null!);
	const { buttonProps } = useButton(props, ref);

	return (
		<Button {...buttonProps} size={props.size} ref={ref}>
			{children}
		</Button>
	);
};
