import { PropsWithChildren } from 'react';

import { Controller, Path, PathValue } from 'react-hook-form';

import { getInputStatusFromError, stringToText } from '@agentero/utils';

import { useAgFormContext } from '../Form';
import { Field, FieldGenericProps } from '../shared/Field';
import { Label } from '../shared/field/Label';
import { getFieldError } from '../shared/formGroupUtils';
import { InputSignatureCanvas } from './fieldSignatureCanvas/InputSignatureCanvas';
import { InputVariants } from './fieldText/Input';

type FieldSignatureCanvasProps<T> = {
	onChange?: (value: string) => void;
	defaultValue?: string;
} & FieldGenericProps<T> &
	InputVariants;

export const FieldSignatureCanvas = <T extends {}>({
	name,
	defaultValue,
	children,
	...formGroupProps
}: PropsWithChildren<FieldSignatureCanvasProps<T>>) => {
	const {
		control,
		formState: { errors },
		isReadonly,
		getValues
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return isReadonly ? (
		<>
			<Label>{formGroupProps.label}</Label>
			{getValues(name) ? <img src={stringToText(getValues(name))} alt="Signature" /> : '-'}
		</>
	) : (
		<Field name={name} nestedChildren={children} {...formGroupProps}>
			<Controller
				control={control}
				name={name}
				defaultValue={defaultValue as PathValue<T, Path<T>>}
				render={({ field: { onChange } }) => {
					return (
						<InputSignatureCanvas
							onChange={value => onChange?.(value ?? '')}
							defaultValue={defaultValue}
							status={getInputStatusFromError(fieldError?.message as string)}
						/>
					);
				}}
			/>
		</Field>
	);
};
