import { useState } from 'react';

import { Flex, Stack } from '@agentero/styles/jsx';

import { Button } from '../../../../Button';
import { RangeSlider } from '../../../../inputs/RangeSlider';
import { BaseSearch } from '../../../DataTableFilters';
import { NumberRangeFilterProps, NumberRangeState } from '../../shared/NumberRangeFilter';

export const SidebarNumberRangeFilter = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange,
	isClearable
}: NumberRangeFilterProps<Search>) => {
	const rangeState = search[filter.key] as NumberRangeState;

	const [value, setValue] = useState<number[]>(() =>
		rangeState
			? [rangeState?.start, rangeState?.end]
			: [filter.config.minValue, filter.config.maxValue]
	);

	const onChangeEnd = ([start, end]: number[]) => {
		const newSearch = { ...search, [filter.key]: { start, end } };
		onSearchChange(newSearch);
	};

	const clearFilter = () => {
		setValue([filter.config.minValue, filter.config.maxValue]);
		const newSearch = { ...search, [filter.key]: undefined };
		onSearchChange(newSearch);
	};

	return (
		<Stack paddingBlockStart="4">
			<RangeSlider
				{...filter.config}
				onChangeEnd={onChangeEnd}
				onChange={setValue}
				aria-label={filter.label}
				value={value}
			/>
			{isClearable && (
				<Flex>
					<Button variant="link" onClick={clearFilter}>
						Clear filter
					</Button>
				</Flex>
			)}
		</Stack>
	);
};
