import { Table } from '@tanstack/react-table';

import { RecipeVariantProps, cva } from '@agentero/styles/css';

import { Pagination } from '../Pagination';

export type PaginationState = {
	offset: number;
	limit: number;
};

// TODO: Review styles and variants when NEXT_PUBLIC_REDESIGN_FEATURE flag is removed. Consider using a slot recipe for all data table components.
const dataTablePaginationStyles = cva({
	base: {
		display: 'flex'
	},
	variants: {
		embed: {
			true: {
				justifyContent: 'flex-end',
				paddingBlock: '12',
				paddingInline: '24',
				borderBlockStart: '0.0625rem solid token(colors.border.default.base.primary)'
			}
		}
	}
});

type DataTablePaginationProps<TData> = {
	table?: Table<TData>;
	state: PaginationState;
	onPageStateChange: (state: PaginationState) => void;
} & RecipeVariantProps<typeof dataTablePaginationStyles>;

export const DataTablePagination = <TData,>({
	table,
	state,
	onPageStateChange,
	embed
}: DataTablePaginationProps<TData>) => {
	const onPageChange = (page: number) => {
		const newPaginationState = { ...state, offset: page };
		onPageStateChange(newPaginationState);
	};

	return table ? (
		<div className={dataTablePaginationStyles({ embed })}>
			<Pagination
				onPageChange={onPageChange}
				totalCount={table.getPageCount()}
				currentPage={table.getState().pagination.pageIndex + 1}
				pageSize={table.getState().pagination.pageSize}
			/>
		</div>
	) : null;
};
