import { useState } from 'react';

import { DefaultValues, useForm } from 'react-hook-form';

import { FilterListIcon, IconClose } from '@agentero/icons';
import { css } from '@agentero/styles/css';
import { Box, HStack, Stack } from '@agentero/styles/jsx';

import { Button } from '../../../Button';
import { Modal } from '../../../Modal';
import { Text } from '../../../Text';
import { Form } from '../../../form/Form';
import { BaseSearch, Filters } from '../../DataTableFilters';
import { SidebarFilterItem } from '../dataTableSidebarFilters/SidebarFilterItem';
import { getTotalFiltersCount } from './dataTableFiltersButton/getTotalFiltersCount';

const filtersButtonStyles = css({
	'&[data-count]:not([data-count="0"])': {
		position: 'relative',
		borderStyle: 'solid',
		borderColor: 'slate.700',
		borderWidth: '2px',

		_before: {
			content: 'attr(data-count)',
			position: 'absolute',
			top: '-0.5rem',
			right: '-0.5rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			paddingInline: '0.25rem',
			minWidth: '1.25rem',
			height: '1.25rem',
			color: 'white',
			backgroundColor: 'slate.700',
			borderRadius: 'full',
			textStyle: 'tiny.base',
			fontWeight: '600',
			borderWidth: '2px',
			borderStyle: 'solid',
			borderColor: 'white'
		}
	}
});

export type DataTableFiltersButtonProps<Search extends BaseSearch> = {
	filters: Filters<Search>;
	search: Search;
	onSearchChange: (search: Search) => void;
};

export const DataTableFiltersButton = <Search extends BaseSearch>({
	filters,
	search,
	onSearchChange
}: DataTableFiltersButtonProps<Search>) => {
	const totalFiltersCount = getTotalFiltersCount(search);
	const [formSearch, setFormSearch] = useState<Search>(search);
	const [resetKey, setResetKey] = useState(0);

	const form = useForm<Search>({
		defaultValues: search as DefaultValues<Search>
	});

	const handleFormFilterChange = (newSearch: Search) => {
		setFormSearch(newSearch);
		form.reset(newSearch);
	};

	const handleApplyFilters = (values: Search) => {
		onSearchChange({ ...values, searchText: search.searchText });
	};

	const handleReset = () => {
		const resetSearch = { ...search } as Search;

		Object.keys(resetSearch).forEach(key => {
			if (key !== 'searchText') {
				const typedKey = key as keyof Search;

				const filterDef = filters.find(f => f.key === typedKey);

				if (filterDef && filterDef.type === 'date-range') {
					(resetSearch[typedKey] as unknown) = undefined;
				} else if (Array.isArray(resetSearch[typedKey])) {
					(resetSearch[typedKey] as unknown) = [];
				} else if (typeof resetSearch[typedKey] === 'object' && resetSearch[typedKey] !== null) {
					(resetSearch[typedKey] as unknown) = undefined;
				} else {
					(resetSearch[typedKey] as unknown) = '';
				}
			}
		});

		setResetKey(prev => prev + 1);
		setFormSearch(resetSearch);
		form.reset(resetSearch);
	};

	return (
		<Modal.Root>
			{({ close }) => (
				<>
					<Modal.Trigger>
						<Button
							variant="ghost"
							size="sm"
							className={filtersButtonStyles}
							data-count={totalFiltersCount}>
							<FilterListIcon />
							Filters
						</Button>
					</Modal.Trigger>
					<Modal.Content className={css({ '--padding': '0 !important', gap: '0 !important' })}>
						<HStack
							gap="0.75rem"
							padding="0.75rem"
							borderBottomStyle="solid"
							borderBottomWidth="1px"
							borderBottomColor="border.default.base.primary">
							<Text
								as="h3"
								size="body.small"
								className={css({ flex: 1, paddingLeft: '44px', textAlign: 'center' })}>
								<b>Filters</b>
							</Text>
							<Button variant="ghost" onClick={close}>
								<IconClose />
							</Button>
						</HStack>
						<Box paddingInline="24" paddingBlock="32" overflow="auto">
							<Form
								id="data-table-filters"
								methods={form}
								ariaLabel="data table filters"
								onSubmit={(values: Search) => {
									handleApplyFilters(values);
									close();
								}}>
								{!!filters.length && (
									<Stack gap="24">
										{filters.map(filter => (
											<Stack gap="12" key={`${filter.key}-${resetKey}`}>
												<Text as="h4" size="body.small">
													<b>{filter.label}</b>
												</Text>
												<SidebarFilterItem.Content
													filter={filter}
													search={formSearch}
													onSearchChange={handleFormFilterChange}
													isClearable={false}
													key={`filter-content-${filter.key}-${resetKey}`}
												/>
											</Stack>
										))}
									</Stack>
								)}
							</Form>
						</Box>
						<HStack
							justifyContent="space-between"
							paddingInline="1.5rem"
							paddingBlock="1rem"
							borderTopStyle="solid"
							borderTopWidth="1px"
							borderTopColor="border.default.base.primary">
							<Button
								type="reset"
								form="data-table-filters"
								variant="ghost"
								size="md"
								onClick={handleReset}>
								Clear All
							</Button>
							<Button type="submit" form="data-table-filters" size="md">
								Apply Filters
							</Button>
						</HStack>
					</Modal.Content>
				</>
			)}
		</Modal.Root>
	);
};
