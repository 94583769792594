import { useState } from 'react';

import { useRouter } from 'next/router';

import { ProducerIdStatus } from '@agentero/models/agent';
import { FeatureFlagKey } from '@agentero/models/featureflags';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useFeatureFlagsQuery } from 'packages/services/fetch/feature-flags-fe/useFeatureFlagsQuery';
import { UserSetting } from 'packages/services/user-settings/UserSetting';
import { useUserSettings } from 'packages/services/user-settings/useUserSettings';

import { ProducerIdAccessModal } from './ProducerIdAccessModal';

export const ProducerIdAccessModalTrigger = () => {
	const [isModalOpen, setIsModalOpen] = useState(true);

	const { query } = useRouter();

	const isQueryUrlActive = query.producerIdAccess === 'true';

	const {
		data: { producerIdStatus, subscription }
	} = useAgentResource();

	const { isSettingActive, deactivateSetting } = useUserSettings(UserSetting.ProducerIdAccessModal);

	const isFeatureFlagActive = useFeatureFlagsQuery()[FeatureFlagKey.ProducerIdEliteAccess];

	const onClose = () => {
		setIsModalOpen(false);
		deactivateSetting();
	};

	return subscription &&
		!subscription.isPaymentFailed &&
		isFeatureFlagActive &&
		(isQueryUrlActive || isSettingActive) &&
		producerIdStatus === ProducerIdStatus.Available ? (
		<>
			<ProducerIdAccessModal isOpen={isModalOpen} onClose={onClose} />
		</>
	) : null;
};
