import { HTMLAttributes, PropsWithChildren } from 'react';

export const TableHead = ({
	children,
	...props
}: PropsWithChildren<HTMLAttributes<HTMLTableSectionElement>>) => {
	return (
		<thead data-slot="table-head" {...props}>
			{children}
		</thead>
	);
};
