import { HTMLAttributes, PropsWithChildren } from 'react';

import { cva } from '@agentero/styles/css';

const tableBodyStyles = cva({
	base: {
		'& [data-slot="table-row"]': {
			borderTop: 'var(--border)',
			transition: 'background-color 0.15s',
			_hover: {
				backgroundColor: 'background.default.base.secondary'
			}
		}
	}
});

export const TableBody = ({
	children,
	...props
}: PropsWithChildren<HTMLAttributes<HTMLTableSectionElement>>) => (
	<tbody data-slot="table-body" className={tableBodyStyles()} {...props}>
		{children}
	</tbody>
);
