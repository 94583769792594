import styled from '@emotion/styled';

export const AppliedFiltersContainer = styled.div<{
	isAllFiltersVisible: boolean;
	hasFilters: boolean;
}>`
	clear: both;
	display: ${({ isAllFiltersVisible }) => (isAllFiltersVisible ? 'block' : 'flex')};
	overflow: ${({ isAllFiltersVisible }) => (isAllFiltersVisible ? 'visible' : 'hidden')};
	max-height: ${({ isAllFiltersVisible }) => (isAllFiltersVisible ? 'auto' : '32px')};
	align-items: flex-start;
	padding-right: 8px;
	margin-bottom: ${({ hasFilters }) => (hasFilters ? '8px' : '0')};

	${({ theme }) => theme.mediaqueries.L} {
		clear: inherit;
	}
`;

export const AppliedFiltersWidth = styled.div`
	display: flex;
	height: 0px;
	clear: both;

	${({ theme }) => theme.mediaqueries.L} {
		clear: inherit;
	}
`;

export const AppliedFiltersButtons = styled.div<{ hasSpace: boolean }>`
	flex: 1;
	min-width: 0;

	${({ theme }) => theme.mediaqueries.S} {
		flex: inherit;
		width: auto;
	}
`;

export const AppliedFiltersButton = styled.div`
	float: left;
	position: relative;
	padding: 4px 9px;
	padding-right: 32px;
	margin: 0 8px 8px 0;
	${props => props.theme.textSettings.S}
	${props => props.theme.fontWeight.medium}
	vertical-align: top;
	max-width: calc(100% - 16px);
	background: ${props => props.theme.colors.gray.lighten90};

	${({ theme }) => theme.mediaqueries.S} {
		max-width: inherit;
	}
`;

export const AppliedFiltersButtonIcon = styled.button`
	border: none;
	padding: 4px;
	background: transparent;
	position: absolute;
	height: 16px;
	padding: 0;
	top: 50%;
	right: 9px;
	border-radius: 0;
	transform: translateY(-50%);
	outline: none;
	cursor: pointer;

	:hover {
		background: ${props => props.theme.colors.gray.lighten80};
	}

	:active {
		background: ${props => props.theme.colors.gray.lighten40};
	}

	svg {
		width: 16px;
		height: 16px;
	}
`;

export const AppliedFiltersActions = styled.div<{ isAllFiltersVisible: boolean }>`
	float: ${({ isAllFiltersVisible }) => (isAllFiltersVisible ? 'left' : 'none')};
	flex: 0;
	display: inline-flex;
	white-space: nowrap;
	vertical-align: top;
	justify-content: flex-end;

	${({ theme }) => theme.mediaqueries.S} {
		justify-content: flex-start;
	}
`;

export const AppliedFiltersActionsItem = styled.div`
	position: relative;
	display: inline-block;
	padding-right: 16px;

	&:after {
		content: '';
		position: absolute;
		right: 7px;
		top: 50%;
		width: 2px;
		height: 16px;
		margin-top: -8px;
		background: ${({ theme }) => theme.colors.gray.lighten90};
	}

	&:last-of-type {
		padding-right: 0;

		&:after {
			display: none;
		}
	}
`;
