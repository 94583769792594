import {
	ChangeEventHandler,
	DetailedHTMLProps,
	InputHTMLAttributes,
	forwardRef,
	useImperativeHandle,
	useRef
} from 'react';

import { Input, InputVariants } from '../fieldText/Input';
import { applyMask } from './inputTel/applyMask';
import { getCursorPosition } from './inputTel/getCursorPosition';

const removeNonDigits = (value: string): string => value.replace(/\D/g, '');

const getDeletionType = (inputType?: string) => {
	const isDeletion = inputType?.toLocaleLowerCase().includes('delete') ?? false;
	if (!isDeletion) return undefined;

	return inputType?.toLocaleLowerCase().includes('forward') ? 'forward' : 'backward';
};

type InputTelProps = InputVariants &
	Omit<
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
		'css' | 'size' | 'onChange' | 'value'
	> & {
		value?: string;
		onChange?: (value: string) => void;
	};

export const InputTel = forwardRef<HTMLInputElement, InputTelProps>(
	({ status, onChange, value, defaultValue, ...props }, externalRef) => {
		const inputRef = useRef<HTMLInputElement>(null);
		const phoneBeforeInput = useRef<string>(value || (defaultValue as string) || '');

		// Sync external ref with internal ref
		useImperativeHandle(externalRef, () => inputRef.current!);

		const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
			const nativeEvent = event.nativeEvent as InputEvent;

			const inputType = nativeEvent.inputType;
			const deletion = getDeletionType(inputType);

			const { value, selectionStart } = event.target;
			const cursorPositionAfterInput = selectionStart ?? 0;
			const userInput = value;

			const maskedValue = applyMask({
				value: removeNonDigits(value),
				mask: '(...) ...-....',
				maskSymbol: '.',
				trimNonMaskCharsLeftover: deletion === 'backward'
			});

			const newCursorPosition = getCursorPosition({
				cursorPositionAfterInput,
				phoneBeforeInput: phoneBeforeInput.current,
				phoneAfterInput: userInput,
				phoneAfterFormatted: maskedValue,
				deletion
			});

			phoneBeforeInput.current = userInput;

			if (onChange) {
				onChange?.(maskedValue);
				/**
				 * HACK: should set cursor on the next tick to make sure that the phone value is updated
				 * useTimeout with 0ms provides issues when two keys are pressed same time
				 */
				Promise.resolve().then(() => {
					// workaround for safari autofocus bug:
					// Check if the input is focused before setting the cursor, otherwise safari sometimes autofocuses on setSelectionRange
					if (typeof window === 'undefined' || inputRef.current !== document?.activeElement) {
						return;
					}
					inputRef.current!.setSelectionRange(newCursorPosition, newCursorPosition);
				});
			} else {
				inputRef.current!.value = maskedValue;
				inputRef.current!.setSelectionRange(newCursorPosition, newCursorPosition);
			}
		};

		return (
			<Input
				onChange={handleChange}
				value={value}
				defaultValue={defaultValue}
				type="tel"
				status={status}
				{...props}
				ref={inputRef}
			/>
		);
	}
);
