import { useRef } from 'react';

import { AriaDateRangePickerProps, DateValue, useDateRangePicker } from '@react-aria/datepicker';
import { useDateRangePickerState } from '@react-stately/datepicker';

import { IconCalendarToday } from '@agentero/icons';
import { Box, HStack } from '@agentero/styles/jsx';

import { CalendarRange } from '../CalendarRange';
import { Dialog } from '../Dialog';
import { DateField } from './datepicker/DateInput';
import { DatePickerButton } from './datepicker/DatePickerButton';
import { DatepickerPopover } from './datepicker/DatepickerPopover';
import { InputSegment } from './shared/InputSegment';

export const DatePickerRange = (props: AriaDateRangePickerProps<DateValue>) => {
	const state = useDateRangePickerState(props);
	const ref = useRef<HTMLDivElement>(null!);
	const { groupProps, startFieldProps, endFieldProps, buttonProps, dialogProps, calendarProps } =
		useDateRangePicker(props, state, ref);

	return (
		<Box position="relative" flexDirection="column" textAlign="left">
			<InputSegment {...groupProps} ref={ref}>
				<HStack gap="2" overflow="auto" paddingInlineEnd="8">
					<DateField {...startFieldProps} />
					<span>-</span>
					<DateField {...endFieldProps} />
				</HStack>
				<DatePickerButton {...buttonProps} isPressed={state.isOpen}>
					<IconCalendarToday />
				</DatePickerButton>
			</InputSegment>
			{state.isOpen && (
				<DatepickerPopover triggerRef={ref} state={state} placement="bottom end" offset={8}>
					<Dialog {...dialogProps}>
						<CalendarRange {...calendarProps} />
					</Dialog>
				</DatepickerPopover>
			)}
		</Box>
	);
};
