import { PropsWithChildren } from 'react';

import { Overlay } from '@react-aria/overlays';

import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

import { Dialog } from '../Dialog';
import { useAgModal } from '../Modal';

const ModalOverlay = styled('div', {
	base: {
		position: 'fixed',
		zIndex: 'modal',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'overlay.background'
	}
});

const ModalContent = styled('div', {
	base: {
		'--padding': 'token(spacing.40)',
		display: 'flex',
		flexDirection: 'column',
		gap: '24',
		paddingBlock: 'var(--padding)',
		background: 'white',
		borderRadius: 'lg',
		maxHeight: 'calc(100dvh - 4rem)',
		width: 'calc(100vw - 2rem)'
	},
	variants: {
		size: {
			sm: {
				maxWidth: '25rem'
			},
			md: {
				maxWidth: '33.75rem'
			},
			lg: {
				maxWidth: '45rem'
			}
		}
	},
	defaultVariants: {
		size: 'md'
	}
});

export type ModalContentVariantProps = StyledVariantProps<typeof ModalContent>;

type ContentProps = {
	className?: string;
};

export const Content = ({ children, className }: PropsWithChildren<ContentProps>) => {
	const { state, underlayProps, modalProps, modalRef, variant, size } = useAgModal();

	return (
		<>
			{state.isOpen && (
				<Overlay>
					<ModalOverlay {...underlayProps}>
						<Dialog role={variant === 'alert' ? 'alertdialog' : 'dialog'} boxShadow="2">
							<ModalContent size={size} {...modalProps} ref={modalRef} className={className}>
								{children}
							</ModalContent>
						</Dialog>
					</ModalOverlay>
				</Overlay>
			)}
		</>
	);
};
