'use client';

import { flexRender } from '@tanstack/react-table';

import { IconArrowUpward, IconSwapVert } from '@agentero/icons';
import { Box, HStack, styled } from '@agentero/styles/jsx';

import { Table, TableRootProps } from './Table';
import { DataTableBody } from './dataTable/DataTableBody';
import { DataTableContent } from './dataTable/DataTableContent';
import { BaseSearch, DataTableFilters } from './dataTable/DataTableFilters';
import { DataTableLoadingOverlay } from './dataTable/DataTableLoadingOverlay';
import { DataTablePagination } from './dataTable/DataTablePagination';
import { DataTableRoot } from './dataTable/DataTableRoot';
import { VirtualizedDataTableBody } from './dataTable/VirtualizedDataTableBody';
import { UseDataTableProps, useDataTable } from './dataTable/useDataTable';

const OrderIcon = styled(IconArrowUpward, {
	base: {
		width: '16',
		height: '16',
		transition: 'transform',
		transitionDuration: '0.2s'
	},
	variants: {
		direction: {
			asc: {},
			desc: {
				transform: 'rotate(180deg)'
			},
			false: {}
		}
	}
});

const SwapVertIcon = styled(IconSwapVert, {
	base: {
		width: '16',
		height: '16'
	}
});

export type DataTableProps<TData, TValue, TSearch extends BaseSearch> = UseDataTableProps<
	TData,
	TValue,
	TSearch
> & {
	isLoading?: boolean;
	maxHeight?: number;
	embed?: boolean;
} & TableRootProps;

export const DataTable = <TData, TValue, TSearch extends BaseSearch = never>({
	data,
	columns,
	pagination,
	search,
	isLoading = false,
	sorting,
	virtualize,
	keepScrollPosition,
	maxHeight,
	embed,
	...props
}: DataTableProps<TData, TValue, TSearch>) => {
	const { table, layout, parentRef, rows, items, before, after } = useDataTable({
		data,
		columns,
		pagination,
		search,
		sorting,
		virtualize,
		keepScrollPosition
	});

	return (
		<DataTableRoot embed={embed} layout={layout}>
			{search && (
				<DataTableFilters
					filters={search.filters}
					layout={layout}
					search={search.state}
					onSearchChange={search.onSearchChange}
				/>
			)}
			<DataTableContent embed={embed}>
				<DataTableLoadingOverlay isLoading={isLoading}>
					<Table.Root
						{...props}
						embed={embed}
						ref={virtualize ? parentRef : undefined}
						maxHeight={maxHeight ? maxHeight : virtualize ? 384 : undefined}>
						<Table.Head>
							{table.getHeaderGroups().map(headerGroup => (
								<Table.Row key={headerGroup.id}>
									{headerGroup.headers.map(header => {
										return (
											<Table.Header
												key={header.id}
												{...header.getContext().header.column.columnDef.meta}>
												{header.isPlaceholder ? null : (
													<HStack
														cursor={header.column.getCanSort() ? 'pointer' : 'default'}
														onClick={header.column.getToggleSortingHandler()}
														title={
															header.column.getCanSort()
																? header.column.getNextSortingOrder() === 'asc'
																	? 'Sort ascending'
																	: header.column.getNextSortingOrder() === 'desc'
																	? 'Sort descending'
																	: 'Clear sort'
																: undefined
														}
														justifyContent={
															// @ts-expect-error
															header.getContext().header.column.columnDef.meta?.style?.textAlign ===
															'right'
																? 'end'
																: 'start'
														}>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{header.column.getCanSort() && (
															<Box height="16" width="16">
																{header.column.getIsSorted() ? (
																	<OrderIcon direction={header.column.getIsSorted()} />
																) : (
																	<SwapVertIcon />
																)}
															</Box>
														)}
													</HStack>
												)}
											</Table.Header>
										);
									})}
								</Table.Row>
							))}
						</Table.Head>
						{virtualize ? (
							<VirtualizedDataTableBody<TData, TValue>
								before={before}
								after={after}
								items={items}
								rows={rows}
								columns={columns}
							/>
						) : (
							<DataTableBody<TData, TValue> rows={rows} columns={columns} />
						)}
					</Table.Root>
				</DataTableLoadingOverlay>
				{pagination && (
					<DataTablePagination
						embed={embed}
						table={table}
						state={pagination.state}
						onPageStateChange={pagination.onPaginationChange}
					/>
				)}
			</DataTableContent>
		</DataTableRoot>
	);
};
