export enum RequestStatusError {
	//OTHER
	Unknown = -1,

	//GRPC statuses
	RpcSuccess = 0,
	RpcCancelled = 1,
	RpcUnknown = 2,
	RpcInvalidArgument = 3,
	RpcDeadlineExceeded = 4,
	RpcNotFound = 5,
	RpcAlreadyExist = 6,
	RpcPermissionDenied = 7,
	RpcResourceExhausted = 8,
	RpcFailedPrecondition = 9,
	RpcAborted = 10,
	RpcOutOfRange = 11,
	RpcUnimplemented = 12,
	RpcInternal = 13,
	RpcUnavailable = 14,
	RpcDataLoss = 15,
	RpcUnauthenticated = 16,

	//HTTP Statuses
	HttpContinue = 100,
	HttpSwitchingProtocols = 101,
	HttpProcessing = 102,
	HttpEarlyHints = 103,
	HttpOk = 200,
	HttpCreated = 201,
	HttpAccepted = 202,
	HttpNonAuthoritative = 203,
	HttpNoContent = 204,
	HttpResetContent = 205,
	HttpPartialContent = 206,
	HttpMultiStatus = 207,
	HttpAlreadyReported = 208,
	HttpImUsed = 226,
	HttpMultipleChoices = 300,
	HttpMovedPermanently = 301,
	HttpFound = 302,
	HttpSeeOther = 303,
	HttpNotModified = 304,
	HttpUseProxy = 305,
	HttpSwitchProxy = 306,
	HttpTemporaryRedirect = 307,
	HttpPermanentRedirect = 308,
	HttpBadRequest = 400,
	HttpUnauthorized = 401,
	HttpPaymentRequired = 402,
	HttpForbidden = 403,
	HttpNotFound = 404,
	HttpMethodNotAllowed = 405,
	HttpNotAcceptable = 406,
	HttpProxyAuthenticationRequired = 407,
	HttpRequestTimeout = 408,
	HttpConflict = 409,
	HttpGone = 410,
	HttpLengthRequired = 411,
	HttpPreconditionFailed = 412,
	HttpPayloadTooLarge = 413,
	HttpUriTooLong = 414,
	HttpUnsupportedMediaType = 415,
	HttpRangeNotSatisfied = 416,
	HttpExpectationFailed = 417,
	HttpIAmTeapot = 418,
	HttpMisdirectedRequest = 421,
	HttpLocked = 423,
	HttpFailedDependency = 424,
	HttpTooEarly = 425,
	HttpUpgradeRequired = 426,
	HttpPreconditionRequired = 428,
	HttpTooManyRequest = 429,
	HttpRequestHeaderFieldsTooLarge = 431,
	HttpUnavailableForLegalReasons = 451,
	HttpClientClosedRequest = 499,
	HttpInternalServerError = 500,
	HttpNotImplemented = 501,
	HttpBadGetaway = 502,
	HttpServiceUnavailable = 503,
	HttpGatewayTimeout = 504,
	HttpVersionNotSupported = 505,
	HttpVariantAlsoNegotiates = 506,
	HttpInsufficientStorage = 507,
	HttpLoopDetected = 508,
	HttpNotExtended = 510,
	HttpNetworkAuthenticationRequired = 511,

	// Request process
	InvalidDataReceived = 666
}
const grpcMatchHttp: { [key: number]: RequestStatusError } = {
	[RequestStatusError.RpcSuccess]: RequestStatusError.HttpOk,
	[RequestStatusError.RpcCancelled]: RequestStatusError.HttpClientClosedRequest,
	[RequestStatusError.RpcUnknown]: RequestStatusError.HttpInternalServerError,
	[RequestStatusError.RpcInvalidArgument]: RequestStatusError.HttpBadRequest,
	[RequestStatusError.RpcDeadlineExceeded]: RequestStatusError.HttpGatewayTimeout,
	[RequestStatusError.RpcNotFound]: RequestStatusError.HttpNotFound,
	[RequestStatusError.RpcAlreadyExist]: RequestStatusError.HttpConflict,
	[RequestStatusError.RpcPermissionDenied]: RequestStatusError.HttpForbidden,
	[RequestStatusError.RpcResourceExhausted]: RequestStatusError.HttpTooManyRequest,
	[RequestStatusError.RpcFailedPrecondition]: RequestStatusError.HttpBadRequest,
	[RequestStatusError.RpcAborted]: RequestStatusError.HttpConflict,
	[RequestStatusError.RpcOutOfRange]: RequestStatusError.HttpBadRequest,
	[RequestStatusError.RpcUnimplemented]: RequestStatusError.HttpNotImplemented,
	[RequestStatusError.RpcInternal]: RequestStatusError.HttpInternalServerError,
	[RequestStatusError.RpcUnavailable]: RequestStatusError.HttpServiceUnavailable,
	[RequestStatusError.RpcDataLoss]: RequestStatusError.HttpInternalServerError,
	[RequestStatusError.RpcUnauthenticated]: RequestStatusError.HttpUnauthorized
};

export const grpcToHttpError = (status: RequestStatusError = 13) => grpcMatchHttp[status] || status;

export const isGRPCError = (status?: number) => {
	return (
		status !== undefined &&
		(status === RequestStatusError.RpcUnknown ||
			(status > RequestStatusError.RpcSuccess && status < RequestStatusError.HttpContinue))
	);
};
