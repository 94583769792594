import { PropsWithChildren, useRef } from 'react';

import { AriaButtonProps, useButton } from '@react-aria/button';

import { Button, ButtonProps } from '../Button';

type ButtonAriaProps = AriaButtonProps<'button'> &
	ButtonProps & {
		isPressed?: boolean;
	};

export const ButtonAria = (props: PropsWithChildren<ButtonAriaProps>) => {
	const { children, variant, size } = props;
	const ref = useRef(null!);
	const { buttonProps } = useButton(props, ref);

	return (
		<Button {...buttonProps} ref={ref} size={size} variant={variant}>
			{children}
		</Button>
	);
};
