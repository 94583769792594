import { Trans, useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalContent } from '@agentero/components';
import { ProducerflowLogo } from '@agentero/icons';
import { Box, Stack } from '@agentero/styles/jsx';
import { Alert, Button, Text } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useOnboardAgencyToProducerId } from 'packages/services/mutate/producerid-fe/agencyOnboardProducerId/useOnboardAgencyToProducerId';
import { getProducerIdApplicationSignIn } from 'routes';

type ProducerIdAccessModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const ProducerIdAccessModal = ({ isOpen, onClose }: ProducerIdAccessModalProps) => {
	const { t } = useTranslation('tier');

	const {
		data: { email }
	} = useAgentResource();

	const producerIdUrl = getProducerIdApplicationSignIn(email);

	const { mutate: onBoardAgency, isPending, isError, isSuccess } = useOnboardAgencyToProducerId();

	return (
		<Modal isOpen={isOpen} onDismiss={onClose} size="lg">
			<ModalContent>
				<ModalBody>
					<Stack gap="32" marginBlockStart="56" marginBlockEnd="32">
						<Stack gap="16" justifyContent="center" alignItems="start">
							<Text size="title.section">{t('producer-id-modal.modal.title')}</Text>
							<Text color="weak">{t('producer-id-modal.modal.subtitle')}</Text>
						</Stack>
						<Stack gap="16" alignItems="center" marginX="16">
							<ProducerflowLogo />
							<Text align="center">{t('producer-id-modal.modal.resume')}</Text>
						</Stack>
						{isError ? (
							<>
								<Alert color="danger" size="lg">
									<Alert.Content>
										<Alert.Title>
											<b>{t('producer-id-modal.modal.error.title')}</b>
										</Alert.Title>
										<Alert.Paragraph>{t('producer-id-modal.modal.error.p1')}</Alert.Paragraph>
										<Alert.Paragraph>
											<Trans
												t={t}
												i18nKey="producer-id-modal.modal.error.p2"
												components={{
													a: <a href="mailto:support@agentero.com" />
												}}
											/>
										</Alert.Paragraph>
									</Alert.Content>
								</Alert>
								<Box marginBlockStart="auto">
									<Stack gap="8" flexDir="column">
										<Button size="md" variant="secondary" onClick={onClose}>
											{t('producer-id-modal.close')}
										</Button>
									</Stack>
								</Box>
							</>
						) : isSuccess ? (
							<>
								<Alert color="success" size="lg">
									<Alert.Content>
										<Alert.Title>
											<b>{t('producer-id-modal.modal.success.title')}</b>
										</Alert.Title>
										<Alert.Paragraph>{t('producer-id-modal.modal.success.p1')}</Alert.Paragraph>
										<Alert.Paragraph>
											<Trans
												t={t}
												i18nKey="producer-id-modal.modal.success.p2"
												components={{
													a: <a href={producerIdUrl} target="_blank" rel="noreferrer" />
												}}
											/>
										</Alert.Paragraph>
									</Alert.Content>
								</Alert>
								<Box marginBlockStart="auto">
									<Stack gap="8" flexDir="column">
										<Button
											as="a"
											size="md"
											variant="primary"
											href={getProducerIdApplicationSignIn(email)}
											target="_blank"
											rel="noreferrer">
											{t('producerid-promo.CTA-visit')}
										</Button>
									</Stack>
								</Box>
							</>
						) : (
							<>
								<Stack gap="8" justifyContent="center" alignItems="start">
									<Text>
										<b>{t('producer-id-modal.modal.intructions.title')}</b>
									</Text>
									<Text>{t('producer-id-modal.modal.intructions.p1')}</Text>
									<Text>{t('producer-id-modal.modal.intructions.p2')}</Text>
									<Text>{t('producer-id-modal.modal.intructions.p3')}</Text>
								</Stack>
								<Box marginBlockStart="auto">
									<Stack gap="8" flexDir="column">
										<Button
											size="md"
											variant="primary"
											loading={isPending}
											disabled={isError}
											onClick={() => onBoardAgency({})}>
											{t('producer-id-modal.cta')}
										</Button>
										<Button
											size="md"
											variant="secondary"
											as="a"
											href={process.env.NEXT_PUBLIC_PRODUCER_ID_LANDING}
											target="_blank"
											rel="noreferrer">
											{t('producer-id-modal.learn-more')}
										</Button>
										<Text size="caption" color="weak" align="center">
											<Trans
												i18nKey="tier:producer-id-modal.help"
												components={{
													a: <a href="mailto:support@agentero.com" />
												}}
											/>
										</Text>
									</Stack>
								</Box>
							</>
						)}
					</Stack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
