import { PropsWithChildren, Ref } from 'react';

import { sva } from '@agentero/styles/css';
import { RecipeVariantProps, SystemStyleObject } from '@agentero/styles/types';

export type TableRootProps = RecipeVariantProps<typeof tableStyles> & { maxHeight?: number };

const stickyHeaderSharedStyles: SystemStyleObject = {
	'& > [data-slot="table-row"]:first-of-type': {
		position: 'sticky',
		top: 0,
		zIndex: 1,
		background: 'bg',
		boxShadow: '0 0.0625rem 0 0 token(colors.border.default.base.primary)'
	}
};

const stickyFooterSharedStyles = {
	'& > [data-slot="table-row"]:last-of-type': {
		position: 'sticky',
		bottom: 0,
		zIndex: 1,
		background: 'bg',
		boxShadow: '0 -0.0625rem 0 0 token(colors.border.default.base.primary)'
	}
};

export const tableStyles = sva({
	slots: ['root', 'scroll', 'table'],
	base: {
		root: {
			'--border': '0.0625rem solid token(colors.border.default.base.primary)',
			overflow: 'hidden',
			flex: '1',
			minHeight: '0',
			display: 'flex',
			flexDirection: 'column'
		},
		scroll: {
			overflow: 'auto',
			WebkitOverflowScrolling: 'touch',
			flex: '1',
			minHeight: '0'
		},
		table: {
			width: '100%',
			'& [data-slot="table-header"], & [data-slot="table-cell"]': {
				verticalAlign: 'middle',
				paddingInline: '16',
				whiteSpace: 'nowrap',
				'&:first-of-type': {
					paddingInlineStart: '24'
				},
				'&:last-of-type': {
					paddingInlineEnd: '24'
				},
				'&:has([type="checkbox"])': {
					width: '0',
					paddingEnd: '0'
				}
			}
		}
	},
	variants: {
		size: {
			xxs: {
				table: {
					'& [data-slot="table-header"]': {
						height: '2rem'
					},
					'& [data-slot="table-header"], & [data-slot="table-cell"]': {
						paddingInline: '2',
						paddingBlock: '2',
						'&:first-of-type': {
							paddingInlineStart: '4'
						},
						'&:last-of-type': {
							paddingInlineEnd: '4'
						}
					}
				}
			},
			xs: {
				table: {
					'& [data-slot="table-header"]': {
						height: '2rem'
					},
					'& [data-slot="table-cell"]': {
						paddingBlock: '8'
					}
				}
			},
			sm: {
				table: {
					'& [data-slot="table-header"]': {
						height: '2.5rem'
					},
					'& [data-slot="table-cell"]': {
						paddingBlock: '16'
					}
				}
			},
			md: {
				table: {
					'& [data-slot="table-header"]': {
						height: '3rem'
					},
					'& [data-slot="table-cell"]': {
						paddingBlock: '24'
					}
				}
			}
		},
		enclosed: {
			true: {
				root: {
					borderRadius: 'md',
					border: 'var(--border)'
				}
			}
		},
		sticky: {
			header: {
				table: {
					'& [data-slot="table-head"]': stickyHeaderSharedStyles
				}
			},
			footer: {
				table: {
					'& [data-slot="table-body"]': stickyFooterSharedStyles
				}
			},
			headerAndFooter: {
				table: {
					'& [data-slot="table-head"]': stickyHeaderSharedStyles,
					'& [data-slot="table-body"]': stickyFooterSharedStyles
				}
			}
		},
		embed: {
			true: {
				table: {
					'& [data-slot="table-body"] [data-slot="table-row"]': {
						borderTop: 'unset'
					},
					'& [data-slot="table-header"], & [data-slot="table-cell"]': {
						'&:not(:has(input[type="checkbox"], [data-slot="table-row-actions"]))': {
							'&:first-of-type': {
								paddingInlineStart: '72'
							},
							'&:last-of-type': {
								paddingInlineEnd: '72'
							}
						}
					}
				}
			}
		}
	},
	defaultVariants: {
		enclosed: true,
		size: 'md'
	}
});

export const TableRoot = ({
	children,
	maxHeight,
	ref,
	...props
}: PropsWithChildren<TableRootProps & { ref?: Ref<HTMLDivElement> }>) => {
	const classes = tableStyles(props);

	return (
		<div data-slot="table-root" className={classes.root}>
			<div
				data-slot="table-scroll"
				ref={ref}
				className={classes.scroll}
				style={{ maxHeight: maxHeight ? `${maxHeight / 16}rem` : undefined }}>
				<table data-slot="table" className={classes.table}>
					{children}
				</table>
			</div>
		</div>
	);
};
