import { useAgentUIStateQuery } from '../fetch/agent-fe/agent-ui-state/useAgentUIState';
import { useAgentUIStateMutation } from '../mutate/agent-fe/update-agent-ui-state/useAgentUIStateMutation';
import { UserSetting } from './UserSetting';

export const useUserSettings = (setting: string | UserSetting) => {
	const { data: userSettings } = useAgentUIStateQuery();
	const { mutate, mutateAsync } = useAgentUIStateMutation();

	return {
		isSettingActive: userSettings[setting] === undefined || userSettings[setting],
		deactivateSetting: () => mutate({ ...userSettings, [setting]: false }),
		deactivateSettingAsync: () => mutateAsync({ ...userSettings, [setting]: false })
	};
};
