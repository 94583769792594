import { HTMLAttributes, PropsWithChildren } from 'react';

import { cva } from '@agentero/styles/css';

const tableHeaderStyles = cva({
	base: {
		height: '3rem',
		textAlign: 'left',
		textStyle: 'body.small',
		fontWeight: '300',
		color: 'text.default.base.tertiary'
	}
});

export const TableHeader = ({
	children,
	...props
}: PropsWithChildren<HTMLAttributes<HTMLTableCellElement>>) => (
	<th data-slot="table-header" className={tableHeaderStyles()} {...props}>
		{children}
	</th>
);
