// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/vehicle.proto (package agentero.portal, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/portal/vehicle.proto.
 */
export const file_agentero_portal_vehicle: GenFile = /*@__PURE__*/
  fileDesc("Ch1hZ2VudGVyby9wb3J0YWwvdmVoaWNsZS5wcm90bxIPYWdlbnRlcm8ucG9ydGFsIh8KD0dldE1ha2VzUmVxdWVzdBIMCgR5ZWFyGAEgASgJIiEKEEdldE1ha2VzUmVzcG9uc2USDQoFbWFrZXMYASADKAkiLgoQR2V0TW9kZWxzUmVxdWVzdBIMCgR5ZWFyGAEgASgJEgwKBG1ha2UYAiABKAkiIwoRR2V0TW9kZWxzUmVzcG9uc2USDgoGbW9kZWxzGAEgAygJIjoKDUdldFZJTlJlcXVlc3QSDAoEeWVhchgBIAEoCRIMCgRtYWtlGAIgASgJEg0KBW1vZGVsGAMgASgJIh0KDkdldFZJTlJlc3BvbnNlEgsKA3ZpbhgBIAEoCTKAAgoOVmVoaWNsZVNlcnZpY2USTwoIR2V0TWFrZXMSIC5hZ2VudGVyby5wb3J0YWwuR2V0TWFrZXNSZXF1ZXN0GiEuYWdlbnRlcm8ucG9ydGFsLkdldE1ha2VzUmVzcG9uc2USUgoJR2V0TW9kZWxzEiEuYWdlbnRlcm8ucG9ydGFsLkdldE1vZGVsc1JlcXVlc3QaIi5hZ2VudGVyby5wb3J0YWwuR2V0TW9kZWxzUmVzcG9uc2USSQoGR2V0VklOEh4uYWdlbnRlcm8ucG9ydGFsLkdldFZJTlJlcXVlc3QaHy5hZ2VudGVyby5wb3J0YWwuR2V0VklOUmVzcG9uc2ViBnByb3RvMw");

/**
 * @generated from message agentero.portal.GetMakesRequest
 */
export type GetMakesRequest = Message<"agentero.portal.GetMakesRequest"> & {
  /**
   * @generated from field: string year = 1;
   */
  year: string;
};

/**
 * Describes the message agentero.portal.GetMakesRequest.
 * Use `create(GetMakesRequestSchema)` to create a new message.
 */
export const GetMakesRequestSchema: GenMessage<GetMakesRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_vehicle, 0);

/**
 * @generated from message agentero.portal.GetMakesResponse
 */
export type GetMakesResponse = Message<"agentero.portal.GetMakesResponse"> & {
  /**
   * @generated from field: repeated string makes = 1;
   */
  makes: string[];
};

/**
 * Describes the message agentero.portal.GetMakesResponse.
 * Use `create(GetMakesResponseSchema)` to create a new message.
 */
export const GetMakesResponseSchema: GenMessage<GetMakesResponse> = /*@__PURE__*/
  messageDesc(file_agentero_portal_vehicle, 1);

/**
 * @generated from message agentero.portal.GetModelsRequest
 */
export type GetModelsRequest = Message<"agentero.portal.GetModelsRequest"> & {
  /**
   * @generated from field: string year = 1;
   */
  year: string;

  /**
   * @generated from field: string make = 2;
   */
  make: string;
};

/**
 * Describes the message agentero.portal.GetModelsRequest.
 * Use `create(GetModelsRequestSchema)` to create a new message.
 */
export const GetModelsRequestSchema: GenMessage<GetModelsRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_vehicle, 2);

/**
 * @generated from message agentero.portal.GetModelsResponse
 */
export type GetModelsResponse = Message<"agentero.portal.GetModelsResponse"> & {
  /**
   * @generated from field: repeated string models = 1;
   */
  models: string[];
};

/**
 * Describes the message agentero.portal.GetModelsResponse.
 * Use `create(GetModelsResponseSchema)` to create a new message.
 */
export const GetModelsResponseSchema: GenMessage<GetModelsResponse> = /*@__PURE__*/
  messageDesc(file_agentero_portal_vehicle, 3);

/**
 * @generated from message agentero.portal.GetVINRequest
 */
export type GetVINRequest = Message<"agentero.portal.GetVINRequest"> & {
  /**
   * @generated from field: string year = 1;
   */
  year: string;

  /**
   * @generated from field: string make = 2;
   */
  make: string;

  /**
   * @generated from field: string model = 3;
   */
  model: string;
};

/**
 * Describes the message agentero.portal.GetVINRequest.
 * Use `create(GetVINRequestSchema)` to create a new message.
 */
export const GetVINRequestSchema: GenMessage<GetVINRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_vehicle, 4);

/**
 * @generated from message agentero.portal.GetVINResponse
 */
export type GetVINResponse = Message<"agentero.portal.GetVINResponse"> & {
  /**
   * @generated from field: string vin = 1;
   */
  vin: string;
};

/**
 * Describes the message agentero.portal.GetVINResponse.
 * Use `create(GetVINResponseSchema)` to create a new message.
 */
export const GetVINResponseSchema: GenMessage<GetVINResponse> = /*@__PURE__*/
  messageDesc(file_agentero_portal_vehicle, 5);

/**
 * @generated from service agentero.portal.VehicleService
 */
export const VehicleService: GenService<{
  /**
   * @generated from rpc agentero.portal.VehicleService.GetMakes
   */
  getMakes: {
    methodKind: "unary";
    input: typeof GetMakesRequestSchema;
    output: typeof GetMakesResponseSchema;
  },
  /**
   * @generated from rpc agentero.portal.VehicleService.GetModels
   */
  getModels: {
    methodKind: "unary";
    input: typeof GetModelsRequestSchema;
    output: typeof GetModelsResponseSchema;
  },
  /**
   * @generated from rpc agentero.portal.VehicleService.GetVIN
   */
  getVIN: {
    methodKind: "unary";
    input: typeof GetVINRequestSchema;
    output: typeof GetVINResponseSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_agentero_portal_vehicle, 0);

