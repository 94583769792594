import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { Agent, parseAgent } from '@agentero/models';
import { RequestError } from '@agentero/service-errors';

import { useAgentUniqueIdentifier } from 'packages/contexts/AgentUniqueIdentifierContext';
import { queryClient } from 'packages/services/QueryCache';
import { portalService } from 'packages/services/connectClients';
import { handleNotAuthorizeRequestError } from 'packages/utilities/withAgentAuth/getInitialPropsActions';

export const currentExpertResourceKey = 'current_expert';

const fetchSession = async (): Promise<Agent> => {
	const response = await fetch('/api/experts/getSession');
	const res = await response.json();
	if (!response.ok) {
		const error = res.error as RequestError;
		handleNotAuthorizeRequestError(error);
		return {} as Agent;
	} else {
		return res as Agent;
	}
};

const fetchLocalAgentSession = async (): Promise<Agent> => {
	const response = await fetch(`${process.env.NEXT_PUBLIC_LOCAL_API_URL}/current_expert`);
	const agent = await response.json();
	return agent;
};

export const getSession = async (init?: RequestInit): Promise<Agent> => {
	const response = await portalService.getSession({}, { headers: init?.headers || {} });
	return parseAgent(response);
};

export const fetchAgentSession = async (init?: RequestInit): Promise<Agent> => {
	if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
		return fetchLocalAgentSession();
	}

	if (typeof window !== 'undefined') {
		return fetchSession();
	}

	try {
		return getSession(init);
	} catch (error) {
		handleNotAuthorizeRequestError(error);
		// This mitigates the error in multiple places where `agent` is used.
		// TODO: Refactor to handle this in a more robust and maintainable way.
		return {} as Agent;
	}
};

export const useAgentResource = () => {
	const { agentUniqueIdentifier } = useAgentUniqueIdentifier();
	return useSuspenseQuery({
		queryKey: [currentExpertResourceKey, agentUniqueIdentifier],
		queryFn: () => fetchAgentSession(),
		gcTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		retry: false
	});
};

export const useInvalidateAgentResource = () => {
	const queryClient = useQueryClient();

	return () => queryClient.invalidateQueries({ queryKey: [currentExpertResourceKey] });
};

export const getAgentData = () => {
	const currentExpert = queryClient.getQueriesData<Agent>({
		exact: false,
		queryKey: [currentExpertResourceKey]
	});

	if (!currentExpert || currentExpert.length === 0) {
		return null;
	}

	return currentExpert[0][1];
};

export const prefetchAgent = (init: RequestInit, agentUniqueIdentifier: string) => {
	return queryClient.fetchQuery({
		queryKey: [currentExpertResourceKey, agentUniqueIdentifier],
		queryFn: () => fetchAgentSession(init),
		staleTime: Infinity,
		retry: 1
	});
};
