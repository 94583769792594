import { cva } from '@agentero/styles/css';
import { Box } from '@agentero/styles/jsx';

import { BaseSearch, Filters } from '../DataTableFilters';
import { DataTableFiltersButton } from './dataTableToolbarFilters/DataTableFiltersButton';
import { TextFilter } from './shared/TextFilter';

type DataTableToolbarFiltersProps<Search extends BaseSearch> = {
	filters: Filters<Search>;
	search: Search;
	onSearchChange: (search: Search) => void;
};

const dataTableToolbarFiltersStyles = cva({
	base: {
		display: 'flex',
		gap: '8',
		paddingBlock: '12',
		paddingInline: '72',
		borderBottom: '0.0625rem solid token(colors.border.default.base.primary)'
	}
});

export const DataTableToolbarFilters = <Search extends BaseSearch>({
	filters,
	search,
	onSearchChange
}: DataTableToolbarFiltersProps<Search>) => (
	<div
		className={dataTableToolbarFiltersStyles()}
		data-slot="data-table-toolbar-filters"
		role="toolbar">
		<Box maxWidth="13.25rem">
			<TextFilter
				search={search}
				filter={{ key: 'searchText', type: 'text' }}
				onSearchChange={newSearch => onSearchChange(newSearch)}
				size="sm"
			/>
		</Box>
		<DataTableFiltersButton filters={filters} search={search} onSearchChange={onSearchChange} />
	</div>
);
