import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';

import { IconCheck } from '@agentero/icons';
import { ITheme } from '@agentero/styles/emotion';

type StepProps = {
	label: string;
	position: number | string;
	isActive: boolean;
	isChecked: boolean;
};

type StepContainerProps = Pick<StepProps, 'isChecked'>;

type StepIconProps = Pick<StepProps, 'isActive' | 'isChecked'>;

type StepLabelProps = Pick<StepProps, 'isActive'>;

export const getStepContainerStyles = ({ theme }: { theme: ITheme }): SerializedStyles => css`
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	&:not(:last-of-type) {
		&:before {
			content: '';
			position: absolute;
			top: ${theme.sizes.getSize(2)};
			left: calc(50% + ${theme.sizes.getSize(3)});
			width: calc(100% - ${theme.sizes.getSize(6)});
			height: ${theme.sizes.getSize(0.25)};
			transform: translateY(-50%);
			background-color: ${theme.colors.gray.lighten80};
		}
	}
`;

const StepContainer = styled.li<StepContainerProps>`
	${getStepContainerStyles}

	&:not(:last-of-type) {
		&:before {
			background-color: ${({ theme, isChecked }) =>
				isChecked ? theme.colors.primary.base : theme.colors.gray.lighten80};
		}
	}
`;

const StepIconContainer = styled.div<StepIconProps>`
	--container-size: ${({ theme }) => theme.sizes.getSize(4)};
	--icon-size: ${({ theme }) => theme.sizes.getSize(2)};

	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: var(--container-size);
	height: var(--container-size);
	margin-bottom: ${({ theme }) => theme.sizes.base};
	color: ${({ isActive, theme }) => (isActive ? theme.colors.white : theme.colors.gray.lighten20)};
	background-color: ${({ isActive, isChecked, theme }) =>
		isActive
			? theme.colors.primary.base
			: isChecked
			? theme.colors.primary.darken30
			: theme.colors.gray.lighten80};
	border-radius: 50%;
	transition: background-color, color, 0.3s ${({ theme }) => theme.easings.easeInOutQuint};

	svg {
		width: var(--icon-size);
		height: var(--icon-size);
		animation: ${({ theme }) =>
			css`
				${theme.animations.scale} 0.3s ${theme.easings.easeOutBack} 1
			`};

		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}
`;

const StepLabel = styled.h2<StepLabelProps>`
	${({ theme }) => theme.textSettings.XS};
	color: ${({ theme, isActive }) =>
		isActive ? theme.colors.gray.base : theme.colors.gray.lighten40};
	transition: color 0.3s ${({ theme }) => theme.easings.easeInOutQuint};
`;

export const Step = ({ label, position, isActive, isChecked }: StepProps) => {
	return (
		<StepContainer aria-current={isActive} isChecked={isChecked}>
			<StepIconContainer
				isActive={isActive}
				isChecked={isChecked}
				data-testid="step-icon-container">
				{isChecked ? <IconCheck role="img" aria-label="check" /> : position}
			</StepIconContainer>
			<StepLabel isActive={isActive}>{label.toUpperCase()}</StepLabel>
		</StepContainer>
	);
};
