import { PropsWithChildren } from 'react';

import { Box } from '@agentero/styles/jsx';

type BodyProps = {
	className?: string;
};

export const Body = ({ children, className }: PropsWithChildren<BodyProps>) => (
	<Box
		className={className}
		overflowY="auto"
		WebkitOverflowScrolling="touch"
		maxH="60vh"
		paddingInline="var(--padding)">
		{children}
	</Box>
);
