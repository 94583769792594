export enum FeatureFlagKey {
	ApplicationsPage = 'applications-page',
	ComplianceWizard = 'compliance-wizard',
	LeadPage = 'lead-page',
	TierCampaignResult = 'tier-campaign-result',
	DashboardSlideshow = 'dashboard-slideshow',
	AppointedCarriersInDashboard = 'appointed-carriers-in-dashboard',
	CarriersTraining = 'carrier-trainings',
	CarrierAppointedModal = 'carrier-appointment-modal',
	BrokerBond = 'broker-bond',
	ProducerIdEliteAccess = 'producer-id-elite-access'
}
