import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { Role } from '@agentero/models/agent';
import { RequestError } from '@agentero/service-errors';

import { queryClient } from '../QueryCache';
import { useAgentResource } from '../fetch/back-ag/useAgentResource';

export type MutationConfig<T, U> = {
	mutation: (token: string, userId: string, data: T) => Promise<U>;
	messages?: {
		transKeys?: string[];
		success?: (t: TFunction, variables: T) => string | undefined;
		error: (error: RequestError) => string | undefined;
	};
	invalidateQueries?: (variable: T, userId: string, role: Role) => (string | number | object)[][];
	removeQueries?: (variable: T, userId: string, role: Role) => (string | number | object)[][];
};

export type UseAgMutationCallbacks<T = {}> = {
	onSuccess?: (variables: T) => void;
	onError?: (error?: RequestError) => void;
};

type UseAgMutation = <T, U>(
	config: MutationConfig<T, U>,
	callbacks?: UseAgMutationCallbacks<T>
) => UseMutationResult<U, RequestError, T, unknown>;

export const useAgMutation: UseAgMutation = (config, callbacks) => {
	const {
		data: { token, id: userId, role }
	} = useAgentResource();
	const { t } = useTranslation(config.messages?.transKeys ?? 'shared');

	return useMutation({
		mutationFn: data => config.mutation(token, userId, data),
		onSuccess: (_, variables) => {
			callbacks?.onSuccess?.(variables);
			if (config.removeQueries) {
				config.removeQueries(variables, userId, role).forEach(query => {
					queryClient.removeQueries({ queryKey: query });
				});
			}

			if (config.invalidateQueries) {
				config.invalidateQueries(variables, userId, role).forEach(query => {
					queryClient.invalidateQueries({ queryKey: query });
				});
			}

			config.messages?.success && toast.success(config.messages.success(t, variables));
		},
		onError: error => {
			callbacks?.onError?.(error);
			const errorMessage = config.messages && config.messages.error(error);

			if (errorMessage) {
				toast.error(t(errorMessage));
			}
		}
	});
};
