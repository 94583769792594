export const getFullName = (firstName: string, lastName: string) =>
	`${firstName} ${lastName}`.replace(/\s+/g, ' ').trim();

export const getFullNameFromReversed = (reversedName: string) => {
	const firstName = reversedName.split(',')[1];
	const lastName = reversedName.split(',')[0];

	return getFullName(firstName, lastName);
};

export const getFullNameWithMiddleName = (
	firstName: string,
	middleName: string,
	lastName: string
) => `${firstName} ${middleName} ${lastName}`.replace(/\s+/g, ' ').trim();
