import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styled from '@emotion/styled';

interface IListRow {
	isActive?: boolean;
	onClick?: () => void;
}

export const StyledListRow = styled.div<IListRow>`
	position: relative;
	display: flex;
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten90};
	padding-bottom: 1px;
	margin-top: -2px;
	transition: background 0.15s;
	background: ${({ theme }) => theme.colors.white};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

	&:before {
		content: '';
		position: absolute;
		z-index: ${({ isActive }) => (isActive ? '2' : '0')};
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;
		border: 2px solid
			${({ theme, isActive }) => (isActive ? theme.colors.primary.base : 'transparent')};
		pointer-events: none;
	}

	&:hover {
		background: ${({ onClick, theme }) => onClick && theme.colors.gray.lighten95};
	}
`;

export const ListRow = (
	props: IListRow & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => <StyledListRow {...props} role="row" />;
