import styled from '@emotion/styled';

import { IconError } from '@agentero/icons';

type FormErrorProps = {
	id?: string;
	error?: string;
	className?: string;
};

const FormErrorContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-top: 8px;
	${({ theme }) => theme.textSettings.XS};
	color: ${({ theme }) => theme.colors.status.error.base};

	svg {
		width: 16px;
		height: 16px;
		min-width: 16px;
		margin-right: 4px;

		path {
			fill: ${({ theme }) => theme.colors.status.error.base};
		}
	}
`;

export const FormError: React.FC<FormErrorProps> = ({ error, className, id }) => {
	return error ? (
		<FormErrorContainer id={id} className={className}>
			<IconError />
			{error}
		</FormErrorContainer>
	) : null;
};
