import { Badge } from '../../../Badge';
import { BaseSearch, Filter } from '../../DataTableFilters';
import { SidebarDateRangeFilter } from './sidebarFilterItem/SidebarDateRangeFilter';
import { SidebarMultipleCheckboxFilter } from './sidebarFilterItem/SidebarMultipleCheckboxFilter';
import { SidebarNumberRangeFilter } from './sidebarFilterItem/SidebarNumberRangeFilter';
import { SidebarSearchMultipleCheckboxFilter } from './sidebarFilterItem/SidebarSearchMultipleCheckboxFilter';

type FilterProps<Search extends BaseSearch> = {
	filter: Filter<Search>;
	search: Search;
	onSearchChange: (search: Search) => void;
	isClearable?: boolean;
};

const SidebarFilterItemContent = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange,
	isClearable
}: FilterProps<Search>) => {
	if (filter.type === 'multiple-checkbox') {
		return (
			<SidebarMultipleCheckboxFilter
				filter={filter}
				search={search}
				onSearchChange={onSearchChange}
				isClearable={isClearable}
			/>
		);
	}

	if (filter.type === 'date-range') {
		return (
			<SidebarDateRangeFilter
				filter={filter}
				search={search}
				onSearchChange={onSearchChange}
				isClearable={isClearable}
			/>
		);
	}

	if (filter.type === 'search-multiple-checkbox') {
		return (
			<SidebarSearchMultipleCheckboxFilter
				filter={filter}
				search={search}
				onSearchChange={onSearchChange}
				isClearable={isClearable}
			/>
		);
	}

	if (filter.type === 'number-range') {
		return (
			<SidebarNumberRangeFilter
				filter={filter}
				search={search}
				onSearchChange={onSearchChange}
				isClearable={isClearable}
			/>
		);
	}

	console.warn(`Filter type ${filter.type} is not supported`);
	return null;
};

type SidebarFilterItemSelectedProps<Search extends BaseSearch> = {
	filter: Filter<Search>;
	search: Search;
};

const SidebarFilterItemSelected = <Search extends BaseSearch>({
	filter,
	search
}: SidebarFilterItemSelectedProps<Search>) => {
	if (filter.type === 'multiple-checkbox' || filter.type === 'search-multiple-checkbox') {
		const selectedItems = (search[filter.key] as unknown[]).length;

		if (selectedItems > 0) {
			return <Badge size="sm">{selectedItems} Selected</Badge>;
		}
	}

	if (filter.type === 'date-range' && search[filter.key]) {
		return null; // TODO: Implement if needed
	}

	return null;
};

export const SidebarFilterItem = {
	Content: SidebarFilterItemContent,
	Selected: SidebarFilterItemSelected
};
