import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	Children,
	DetailedHTMLProps,
	HTMLAttributes,
	PropsWithChildren,
	isValidElement
} from 'react';

import { clsx } from 'clsx';
import Link, { LinkProps } from 'next/link';

import { cva } from '@agentero/styles/css';
import { RecipeVariantProps } from '@agentero/styles/types';

import { Text } from './Text';

export const tagStyle = cva({
	base: {
		display: 'inline-flex',
		gap: '2',
		alignItems: 'center',
		paddingInline: '8',
		paddingBlock: '2',
		borderRadius: 'md',
		textStyle: 'caption.base',
		color: 'slate.700',
		border: '0.0625rem solid token(colors.slate.200)',
		backgroundColor: 'slate.050',
		width: 'fit-content',
		minWidth: 'fit-content',
		textDecoration: 'none',
		transition: 'background-color 0.2s',
		'& svg': {
			minWidth: 'fit-content',
			width: '16',
			height: '16'
		}
	},
	variants: {
		color: {
			green: {
				color: 'positive.700',
				borderColor: 'positive.200',
				backgroundColor: 'positive.050',
				'& svg path': {
					fill: 'positive.700'
				}
			},
			blue: {
				color: 'blue.600',
				borderColor: 'blue.200',
				backgroundColor: 'blue.050',
				'& svg path': {
					fill: 'blue.600'
				}
			},
			yellow: {
				color: 'warning.700',
				borderColor: 'warning.300',
				backgroundColor: 'warning.050',
				'& svg path': {
					fill: 'warning.700'
				}
			},
			red: {
				color: 'danger.500',
				borderColor: 'danger.200',
				backgroundColor: 'danger.050',
				'& svg path': {
					fill: 'danger.500'
				}
			},
			purple: {
				color: 'purple.600',
				borderColor: 'purple.200',
				backgroundColor: 'purple.050',
				'& svg path': {
					fill: 'purple.600'
				}
			},
			pink: {
				color: 'pink.400',
				borderColor: 'pink.200',
				backgroundColor: 'pink.050',
				'& svg path': {
					fill: 'pink.400'
				}
			},
			orange: {
				color: 'orange.500',
				borderColor: 'orange.200',
				backgroundColor: 'orange.050',
				'& svg path': {
					fill: 'orange.500'
				}
			},
			gray: {
				color: 'slate.700',
				borderColor: 'slate.200',
				backgroundColor: 'slate.050',
				'& svg path': {
					fill: 'slate.700'
				}
			}
		},
		variant: {
			secondary: {
				display: 'inline-flex'
			},
			tertiary: {
				borderColor: 'transparent'
			},
			outline: {
				backgroundColor: 'transparent'
			},
			ghost: {
				borderColor: 'transparent',
				backgroundColor: 'transparent'
			}
		},
		hoverable: {
			true: {
				_hover: {
					backgroundColor: 'slate.100',
					cursor: 'pointer'
				}
			}
		},
		pill: {
			true: {
				borderRadius: 'full'
			}
		},
		size: {
			sm: {
				height: '24'
			},
			md: {
				height: '32'
			}
		},
		truncate: {
			true: {
				maxWidth: '100%',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				minWidth: '0'
			}
		}
	},
	compoundVariants: [
		{
			color: 'green',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'positive.100'
				}
			}
		},
		{
			color: 'blue',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'blue.100'
				}
			}
		},
		{
			color: 'yellow',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'warning.100'
				}
			}
		},
		{
			color: 'red',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'danger.100'
				}
			}
		},
		{
			color: 'purple',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'purple.100'
				}
			}
		},
		{
			color: 'pink',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'pink.100'
				}
			}
		},
		{
			color: 'orange',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'orange.100'
				}
			}
		},
		{
			color: 'gray',
			hoverable: true,
			css: {
				_hover: {
					backgroundColor: 'slate.100'
				}
			}
		}
	],
	defaultVariants: {
		color: 'gray',
		variant: 'tertiary',
		hoverable: false,
		pill: false,
		size: 'sm',
		truncate: false
	}
});

type TagBaseProps = {
	as?: 'a' | 'link' | 'button' | 'span';
} & RecipeVariantProps<typeof tagStyle>;

export type TagAsAnchor = TagBaseProps & AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'a' };
export type TagAsButton = TagBaseProps &
	ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };
export type TagAsLink = TagBaseProps &
	LinkProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'link' };
export type TagAsSpan = TagBaseProps &
	DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & { as?: 'span' };

export type TagColor = NonNullable<RecipeVariantProps<typeof tagStyle>>['color'];
export type TagVariant = NonNullable<RecipeVariantProps<typeof tagStyle>>['variant'];
export type TagSize = NonNullable<RecipeVariantProps<typeof tagStyle>>['size'];

export type TagProps = TagAsAnchor | TagAsButton | TagAsLink | TagAsSpan;

export const Tag = ({
	children,
	as = 'span',
	truncate,
	pill,
	size,
	variant,
	color,
	...props
}: PropsWithChildren<TagProps>) => {
	const tagClassName = tagStyle({
		variant,
		hoverable: as !== 'span',
		truncate,
		pill,
		size,
		color
	});

	const className = clsx(tagClassName, props.className);

	// When truncation is enabled, wrap any non-element children (text/numbers) in a
	// truncating Text component. This ensures text truncation works without
	// modifying valid React elements that might handle truncation themselves.
	// If truncate is disabled, return children directly to avoid unnecessary wrapping.
	const content = truncate
		? Children.map(children, child => {
				if (isValidElement(child)) {
					// Preserve existing elements to prevent double wrapping
					return child;
				}
				// Wrap plain text in Text component with truncation styles
				return (
					<Text size="inherit" color="inherit" truncate={truncate}>
						{child}
					</Text>
				);
		  })
		: children;

	if (as === 'a') {
		return (
			<a {...(props as TagAsAnchor)} data-slot="tag" className={className}>
				{content}
			</a>
		);
	}

	if (as === 'link') {
		return (
			<Link {...(props as TagAsLink)} data-slot="tag" className={className}>
				{content}
			</Link>
		);
	}

	if (as === 'button') {
		return (
			<button {...(props as TagAsButton)} data-slot="tag" className={className}>
				{content}
			</button>
		);
	}

	return (
		<span {...props} data-slot="tag" className={className}>
			{content}
		</span>
	);
};
