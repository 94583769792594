import { Code, type ConnectError, type Interceptor } from '@connectrpc/connect';

import { getAgentData } from 'packages/services/fetch/back-ag/useAgentResource';

export const authInterceptor: Interceptor = next => async req => {
	const agent = getAgentData();

	if (agent) {
		req.header.set('Authorization', `Bearer ${agent.token}`);
	}

	try {
		return await next(req);
	} catch (error) {
		const connectError = error as ConnectError;
		if (connectError.code === Code.Unauthenticated) {
			console.log('Unauthenticated in auth interceptor');
			throw error;
		} else if (connectError.code === Code.PermissionDenied) {
			console.log('Permission denied in auth interceptor');
			throw error;
		} else {
			throw error;
		}
	}
};
