import {
	ComponentType,
	PropsWithChildren,
	createContext,
	useCallback,
	useEffect,
	useState
} from 'react';

import styled from '@emotion/styled';

import { FilterListIcon } from '@agentero/icons';
import { Button } from '@agentero/ui';

export type DropdownMenuButtonProps = {
	onClick: () => void;
};

interface IDropdownMenu {
	text?: string;
	icon?: ComponentType<{}>;
	button?: ComponentType<DropdownMenuButtonProps>;
	className?: string;
}

interface IDropdownMenuContext {
	onClose: () => void;
	onOpen: () => void;
}

const StyledDropdownMenuPanel = styled.div`
	position: relative;
`;

const StyledPanel = styled.ul`
	${props => props.theme.textSettings.S}
	width: 240px;
	z-index: 2;
	box-shadow: ${props => props.theme.shadows.hard};
	position: absolute;
	background: ${props => props.theme.colors.white};
`;

export const DropdownMenuContex = createContext<IDropdownMenuContext>({
	onClose: () => null,
	onOpen: () => null
});

export const DropdownMenu = ({
	children,
	text,
	icon: Icon,
	button: ButtonFromProps,
	className,
	...props
}: PropsWithChildren<IDropdownMenu>) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const onToggle = () => setIsOpen(!isOpen);

	return (
		<StyledDropdownMenuPanel {...props}>
			{ButtonFromProps ? (
				<ButtonFromProps onClick={onToggle} />
			) : (
				<Button variant="secondary" onClick={onToggle}>
					{text}
					{Icon ? <Icon /> : <FilterListIcon />}
				</Button>
			)}
			{isOpen && (
				<DropdownMenuPanel className={className} setIsOpen={setIsOpen}>
					{children}
				</DropdownMenuPanel>
			)}
		</StyledDropdownMenuPanel>
	);
};

interface IDropdownMenuPanel {
	setIsOpen: (value: boolean) => void;
	className?: string;
}

const DropdownMenuPanel = ({
	children,
	setIsOpen,
	className
}: PropsWithChildren<IDropdownMenuPanel>) => {
	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
	const onPanelClick = (event: React.MouseEvent) => event.stopPropagation();
	const onOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
	useEffect(() => {
		window.addEventListener('mousedown', onClose, false);
		return () => window.removeEventListener('mousedown', onClose, false);
	}, [onClose]);

	const value = {
		onClose,
		onOpen
	};
	return (
		<DropdownMenuContex.Provider value={value}>
			<StyledPanel className={className} onMouseDown={onPanelClick}>
				{children}
			</StyledPanel>
		</DropdownMenuContex.Provider>
	);
};
