// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/rater/rater.proto (package agentero.rater, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Carrier, QuotingMode } from "../carrier/carrier_pb";
import { file_agentero_carrier_carrier } from "../carrier/carrier_pb";
import type { Bundle, PolicyComponent } from "../policy/policy_pb";
import { file_agentero_policy_policy } from "../policy/policy_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/rater/rater.proto.
 */
export const file_agentero_rater_rater: GenFile = /*@__PURE__*/
  fileDesc("ChphZ2VudGVyby9yYXRlci9yYXRlci5wcm90bxIOYWdlbnRlcm8ucmF0ZXIiyAQKBVF1b3RlEhMKC3dvcmtpdGVtX2lkGAEgASgJEioKB2NhcnJpZXIYAiABKA4yGS5hZ2VudGVyby5jYXJyaWVyLkNhcnJpZXISNAoKY29tcG9uZW50cxgEIAMoCzIgLmFnZW50ZXJvLnBvbGljeS5Qb2xpY3lDb21wb25lbnQSEAoIYmluZF91cmwYBSABKAkSLQoHcHJlbWl1bRgGIAEoCzIcLmFnZW50ZXJvLnJhdGVyLlF1b3RlUHJlbWl1bRIsCgZidW5kbGUYByABKAsyFy5hZ2VudGVyby5wb2xpY3kuQnVuZGxlSACIAQESIQoZaXNfcXVvdGVfcHJvcG9zYWxfYWxsb3dlZBgIIAEoCBIPCgdyZXRyaWVkGAkgASgIEhMKC3N1bW1hcnlfdXJsGAogASgJEjMKDHF1b3RpbmdfbW9kZRgLIAEoDjIdLmFnZW50ZXJvLmNhcnJpZXIuUXVvdGluZ01vZGUSJAoFcmF0ZXIYDCABKA4yFS5hZ2VudGVyby5yYXRlci5SYXRlchIbChNpc19hZ2VudGVyb19jYXJyaWVyGA0gASgIEiEKGWlzX2JpbmRhYmxlX3dpdGhfYWdlbnRlcm8YDiABKAgSJwofaXNfYmluZF93aXRoX2FnZW50ZXJvX3JlcXVlc3RlZBgPIAEoCBIPCgdtZXNzYWdlGBAgASgJEhwKD2JpbmRfcmVxdWVzdF9pZBgRIAEoCUgBiAEBQgkKB19idW5kbGVCEgoQX2JpbmRfcmVxdWVzdF9pZCJJCgxRdW90ZVByZW1pdW0SDgoGYW1vdW50GAEgASgBEikKBHRlcm0YAiABKA4yGy5hZ2VudGVyby5yYXRlci5QcmVtaXVtVGVybSJ9CgdBZGRyZXNzEg4KBnN0cmVldBgBIAEoCRIVCg1zdHJlZXRfbnVtYmVyGAIgASgJEhAKCHppcF9jb2RlGAMgASgJEgwKBGNpdHkYBCABKAkSDQoFc3RhdGUYBSABKAkSDAoEdW5pdBgGIAEoCRIOCgZjb3VudHkYByABKAkqNgoFUmF0ZXISDQoJVU5ERUZJTkVEEAASEgoOSVRDX1RVUkJPUkFURVIQARIKCgZTRU1TRUUQAiopCgtQcmVtaXVtVGVybRIKCgZBTk5VQUwQABIOCgpTSVhfTU9OVEhTEAFiBnByb3RvMw", [file_agentero_carrier_carrier, file_agentero_policy_policy]);

/**
 * @generated from message agentero.rater.Quote
 */
export type Quote = Message<"agentero.rater.Quote"> & {
  /**
   * The id of the workitem that was created for this quote.
   *
   * @generated from field: string workitem_id = 1;
   */
  workitemId: string;

  /**
   * @generated from field: agentero.carrier.Carrier carrier = 2;
   */
  carrier: Carrier;

  /**
   * @generated from field: repeated agentero.policy.PolicyComponent components = 4;
   */
  components: PolicyComponent[];

  /**
   * The URL to the quote page in the carrier portal,
   * for editing the quote and binding.
   *
   * @generated from field: string bind_url = 5;
   */
  bindUrl: string;

  /**
   * @generated from field: agentero.rater.QuotePremium premium = 6;
   */
  premium?: QuotePremium;

  /**
   * @generated from field: optional agentero.policy.Bundle bundle = 7;
   */
  bundle?: Bundle;

  /**
   * @generated from field: bool is_quote_proposal_allowed = 8;
   */
  isQuoteProposalAllowed: boolean;

  /**
   * @generated from field: bool retried = 9;
   */
  retried: boolean;

  /**
   * The URL from the Carrier with information about the quote.
   *
   * @generated from field: string summary_url = 10;
   */
  summaryUrl: string;

  /**
   * @generated from field: agentero.carrier.QuotingMode quoting_mode = 11;
   */
  quotingMode: QuotingMode;

  /**
   * @generated from field: agentero.rater.Rater rater = 12;
   */
  rater: Rater;

  /**
   * @generated from field: bool is_agentero_carrier = 13;
   */
  isAgenteroCarrier: boolean;

  /**
   * @generated from field: bool is_bindable_with_agentero = 14;
   */
  isBindableWithAgentero: boolean;

  /**
   * @generated from field: bool is_bind_with_agentero_requested = 15;
   */
  isBindWithAgenteroRequested: boolean;

  /**
   * @generated from field: string message = 16;
   */
  message: string;

  /**
   * @generated from field: optional string bind_request_id = 17;
   */
  bindRequestId?: string;
};

/**
 * Describes the message agentero.rater.Quote.
 * Use `create(QuoteSchema)` to create a new message.
 */
export const QuoteSchema: GenMessage<Quote> = /*@__PURE__*/
  messageDesc(file_agentero_rater_rater, 0);

/**
 * @generated from message agentero.rater.QuotePremium
 */
export type QuotePremium = Message<"agentero.rater.QuotePremium"> & {
  /**
   * @generated from field: double amount = 1;
   */
  amount: number;

  /**
   * @generated from field: agentero.rater.PremiumTerm term = 2;
   */
  term: PremiumTerm;
};

/**
 * Describes the message agentero.rater.QuotePremium.
 * Use `create(QuotePremiumSchema)` to create a new message.
 */
export const QuotePremiumSchema: GenMessage<QuotePremium> = /*@__PURE__*/
  messageDesc(file_agentero_rater_rater, 1);

/**
 * @generated from message agentero.rater.Address
 */
export type Address = Message<"agentero.rater.Address"> & {
  /**
   * @generated from field: string street = 1;
   */
  street: string;

  /**
   * @generated from field: string street_number = 2;
   */
  streetNumber: string;

  /**
   * @generated from field: string zip_code = 3;
   */
  zipCode: string;

  /**
   * @generated from field: string city = 4;
   */
  city: string;

  /**
   * @generated from field: string state = 5;
   */
  state: string;

  /**
   * @generated from field: string unit = 6;
   */
  unit: string;

  /**
   * @generated from field: string county = 7;
   */
  county: string;
};

/**
 * Describes the message agentero.rater.Address.
 * Use `create(AddressSchema)` to create a new message.
 */
export const AddressSchema: GenMessage<Address> = /*@__PURE__*/
  messageDesc(file_agentero_rater_rater, 2);

/**
 * @generated from enum agentero.rater.Rater
 */
export enum Rater {
  /**
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: ITC_TURBORATER = 1;
   */
  ITC_TURBORATER = 1,

  /**
   * @generated from enum value: SEMSEE = 2;
   */
  SEMSEE = 2,
}

/**
 * Describes the enum agentero.rater.Rater.
 */
export const RaterSchema: GenEnum<Rater> = /*@__PURE__*/
  enumDesc(file_agentero_rater_rater, 0);

/**
 * @generated from enum agentero.rater.PremiumTerm
 */
export enum PremiumTerm {
  /**
   * @generated from enum value: ANNUAL = 0;
   */
  ANNUAL = 0,

  /**
   * @generated from enum value: SIX_MONTHS = 1;
   */
  SIX_MONTHS = 1,
}

/**
 * Describes the enum agentero.rater.PremiumTerm.
 */
export const PremiumTermSchema: GenEnum<PremiumTerm> = /*@__PURE__*/
  enumDesc(file_agentero_rater_rater, 1);

