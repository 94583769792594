import { styled } from '@agentero/styles/jsx';

export const FormItem = styled('div', {
	base: {
		marginBottom: '16px'
	}
});

export const NestedFormItem = styled('div', {
	base: {
		marginTop: '1rem'
	}
});
