import React, { forwardRef, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { InputSearch } from './InputSearch';
import { InputProps } from './form/Input';

export const DEBOUNCE_TIME = 300; // ms

type InputCustomProps = Omit<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'value' | 'onChange'
> & {
	value: string;
	onChange: (newValue: string) => void;
};

type InputSearchDebouncedProps = InputCustomProps & InputProps;

export const InputSearchDebounced = forwardRef<HTMLInputElement, InputSearchDebouncedProps>(
	({ value, onChange, ...props }, ref) => {
		const { inputSearch, onSearchChange } = useDebounceSearch({ value, onChange });

		return (
			//@ts-ignore
			<InputSearch ref={ref} {...props} value={inputSearch} onChange={onSearchChange} />
		);
	}
);

type UseDebounceSearchParams = {
	value: string;
	onChange: (newValue: string) => void;
};

const useDebounceSearch = ({ onChange, value }: UseDebounceSearchParams) => {
	const [inputSearch, setSearch] = useState<string>(value);
	const debounceSearch = useDebouncedCallback(onChange, DEBOUNCE_TIME);

	const onSearchChange = (value: string) => {
		debounceSearch(value);
		setSearch(value);
	};

	return { onSearchChange, inputSearch };
};
