import { MultipleCheckboxFilter } from '../../../../filters/MultipleCheckboxFilter';
import { BaseSearch } from '../../../DataTableFilters';
import { MultipleCheckboxFilterProps } from '../../shared/MultipleCheckboxFilter';

export const SidebarMultipleCheckboxFilter = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange,
	isClearable
}: MultipleCheckboxFilterProps<Search>) => {
	const { options, label } = filter;
	const value = search[filter.key] as string[];

	const onGroupChange = (values: string[]) => {
		const newSearch = { ...search, [filter.key]: values };

		onSearchChange(newSearch);
	};

	return (
		<MultipleCheckboxFilter
			label={label}
			value={value}
			options={options}
			onChange={onGroupChange}
			isClearable={isClearable}
		/>
	);
};
