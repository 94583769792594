import { FC, PropsWithChildren } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { IconCheckCircle } from '@agentero/icons';
import { Avatar } from '@agentero/ui';

import { RoundIcon } from '../RoundIcon';
import { Text } from '../Typography';

export enum TrackerItemStatus {
	Default,
	Disabled,
	Ready
}

type TrackerItemIcon = {
	Isotype: FC;
};

export type TrackerItemProps = {
	id: string;
	label: string;
	icon: TrackerItemIcon;
	status: TrackerItemStatus;
	message?: string;
	onClick?: () => void;
};

const TrackerItemContainer = styled.article<{ withHover: boolean; isSelected: boolean }>`
	display: flex;
	align-items: center;
	min-height: ${({ theme }) => theme.sizes.getSize(5)};
	column-gap: ${({ theme }) => theme.sizes.getSize(1)};
	padding: ${({ theme }) => theme.sizes.getSize(1.5)} ${({ theme }) => theme.sizes.getSize(1)};

	${({ theme, withHover, isSelected }) =>
		withHover &&
		`
			@media (hover: hover) and (pointer: fine) {
				&:hover {
					background-color: ${theme.colors.gray.lighten95};
					cursor: pointer;
				}
			}

			&:active {
				background-color: ${theme.colors.gray.lighten90};
			}

			${isSelected ? `background-color: ${theme.colors.gray.lighten90};` : ''}
	`}

	[role='img'] {
		> svg {
			padding: ${({ theme }) => theme.sizes.getSize(0.5)};
		}
	}
`;

const TrackerItemHeading = styled.hgroup`
	flex: 1;
`;

const TrackerItemIcon = styled.div`
	--icon-size: ${({ theme }) => theme.sizes.getSize(2.5)};

	display: flex;
	align-items: center;

	svg {
		width: var(--icon-size);
		height: var(--icon-size);
		animation: ${({ theme }) =>
			css`
				${theme.animations.scale} 0.3s ${theme.easings.easeOutBack} 1
			`};

		path {
			fill: ${({ theme }) => theme.colors.primary.base};
		}
	}
`;

export const TrackerItem = ({
	label,
	icon,
	status,
	message,
	onClick,
	isSelected,
	children,
	id
}: PropsWithChildren<TrackerItemProps> & { isSelected: boolean }) => {
	const { Isotype } = icon;

	return (
		<TrackerItemContainer
			onClick={onClick}
			withHover={Boolean(onClick)}
			isSelected={isSelected}
			role="gridcell"
			aria-selected={isSelected ? 'true' : undefined}>
			{Isotype ? (
				<RoundIcon
					size={32}
					icon={<Isotype />}
					grayFilter={status === TrackerItemStatus.Disabled}
				/>
			) : (
				<Avatar fallback={id[0]} size="sm" />
			)}
			<TrackerItemHeading>
				<Text as="h3" size="S">
					{label}
				</Text>
				{message && (
					<Text as="p" size="XS" colorize={colors => colors.gray.lighten40} role="log">
						{message}
					</Text>
				)}
			</TrackerItemHeading>
			{children}
			{status === TrackerItemStatus.Ready && (
				<TrackerItemIcon role="status">
					<IconCheckCircle />
				</TrackerItemIcon>
			)}
		</TrackerItemContainer>
	);
};
