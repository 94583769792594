import styled from '@emotion/styled';

export const ListCell = styled.div<{ hasBorder?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1;
	min-width: 0;
	padding: 12px 8px;
	mix-blend-mode: multiply;

	&:first-of-type {
		padding-left: 16px;
	}

	&:last-of-type {
		padding-right: 16px;
	}

	${({ theme, hasBorder }) =>
		hasBorder &&
		`
		&:not(:first-of-type):after {
			content: '';
			position: absolute;
			top: 12px;
			left: 0.5px;
			bottom: 12px;
			width: 1px;
			background: ${theme.colors.gray.lighten90};
		}
	`}
`;
