import { useRef } from 'react';

import { createCalendar } from '@internationalized/date';
import { AriaDatePickerProps, DateValue, useDateField } from '@react-aria/datepicker';
import { useDateFieldState } from '@react-stately/datepicker';

import { HStack } from '@agentero/styles/jsx';

import { DateSegment } from '../shared/DateSegment';

export const DateField = (props: AriaDatePickerProps<DateValue>) => {
	const state = useDateFieldState({
		...props,
		locale: 'en-US',
		createCalendar
	});

	const ref = useRef<HTMLDivElement>(null!);
	const { fieldProps } = useDateField(props, state, ref);

	return (
		<HStack {...fieldProps} ref={ref} gap={0}>
			{state.segments.map((segment, i) => (
				<DateSegment key={i} segment={segment} state={state} />
			))}
		</HStack>
	);
};
