type ApplyMaskArgs = {
	value: string;
	mask: string;
	maskSymbol: string;

	/**
	 * @description Removes all non-maskSymbol chars from the result's ending if the value is shorter than the mask
	 * @example
	 * value: "1234"
	 * mask: "(....) ...."
	 * if true -> "(1234"
	 * if false -> "(1234) "
	 */
	trimNonMaskCharsLeftover?: boolean;
};

export const applyMask = ({
	value,
	mask,
	maskSymbol,
	trimNonMaskCharsLeftover = false
}: ApplyMaskArgs): string => {
	if (!value) return '';

	let result = '';
	let charsPlaced = 0;

	for (const maskChar of mask.split('')) {
		if (charsPlaced >= value.length) {
			if (!trimNonMaskCharsLeftover && maskChar !== maskSymbol) {
				result += maskChar;
				continue;
			}
			break;
		}
		if (maskChar === maskSymbol) {
			result += value[charsPlaced];
			charsPlaced += 1;
		} else {
			result += maskChar;
		}
	}

	return result;
};
