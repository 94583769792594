import { createElement } from 'react';

import styled from '@emotion/styled';

import { Spinner } from '../Spinner';

const ListTableBodyContainer = styled.div`
	margin-top: -1px;
	padding-top: 2px;

	&:before {
		content: '';
		position: sticky;
		display: block;
		z-index: 1;
		top: calc(100% - 1px);
		width: 100%;
		height: 1px;
		background: ${({ theme }) => theme.colors.gray.lighten90};
	}
`;

export const ListTableBodyLoading = styled.div`
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ theme }) => theme.overlay.light};
`;

interface IListBodyProps<T> {
	items: T[];
	children: ListBodyRenderChildren<T>;
	className?: string;
	isFetching?: boolean;
	isSkeleton?: boolean;
	alternateRowsColor?: boolean;
}

interface ListBodyRenderChildrenProps<T> {
	item: T;
}

type ListBodyRenderChildren<T> = React.ComponentType<ListBodyRenderChildrenProps<T>>;

export const ListTableBody = <T extends {}>({
	className,
	children,
	items,
	isFetching
}: IListBodyProps<T>) => {
	return (
		<>
			<ListTableBodyContainer className={className} role="rowgroup">
				{items.map((item, index) =>
					createElement(children, {
						item,
						key: index
					})
				)}
			</ListTableBodyContainer>
			{isFetching && (
				<ListTableBodyLoading>
					<Spinner />
				</ListTableBodyLoading>
			)}
		</>
	);
};
