// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/portal/compliance.proto (package agentero.portal, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import type { EmptySchema } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_empty } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/portal/compliance.proto.
 */
export const file_agentero_portal_compliance: GenFile = /*@__PURE__*/
  fileDesc("CiBhZ2VudGVyby9wb3J0YWwvY29tcGxpYW5jZS5wcm90bxIPYWdlbnRlcm8ucG9ydGFsIlUKIFJlcXVlc3RSZWZyZXNoTGljZW5zZUluZm9SZXF1ZXN0EhMKCWFnZW5jeV9pZBgBIAEoCUgAEhIKCGFnZW50X2lkGAIgASgJSABCCAoGb3B0aW9uIiwKF1J1bk5JUFJGb3JBZ2VuY3lSZXF1ZXN0EhEKCWFnZW5jeV9pZBgBIAEoCSIqChZSdW5OSVBSRm9yQWdlbnRSZXF1ZXN0EhAKCGFnZW50X2lkGAEgASgJMqUCChFDb21wbGlhbmNlU2VydmljZRJmChlSZXF1ZXN0UmVmcmVzaExpY2Vuc2VJbmZvEjEuYWdlbnRlcm8ucG9ydGFsLlJlcXVlc3RSZWZyZXNoTGljZW5zZUluZm9SZXF1ZXN0GhYuZ29vZ2xlLnByb3RvYnVmLkVtcHR5ElQKEFJ1bk5JUFJGb3JBZ2VuY3kSKC5hZ2VudGVyby5wb3J0YWwuUnVuTklQUkZvckFnZW5jeVJlcXVlc3QaFi5nb29nbGUucHJvdG9idWYuRW1wdHkSUgoPUnVuTklQUkZvckFnZW50EicuYWdlbnRlcm8ucG9ydGFsLlJ1bk5JUFJGb3JBZ2VudFJlcXVlc3QaFi5nb29nbGUucHJvdG9idWYuRW1wdHliBnByb3RvMw", [file_google_protobuf_empty]);

/**
 * @generated from message agentero.portal.RequestRefreshLicenseInfoRequest
 */
export type RequestRefreshLicenseInfoRequest = Message<"agentero.portal.RequestRefreshLicenseInfoRequest"> & {
  /**
   * @generated from oneof agentero.portal.RequestRefreshLicenseInfoRequest.option
   */
  option: {
    /**
     * @generated from field: string agency_id = 1;
     */
    value: string;
    case: "agencyId";
  } | {
    /**
     * @generated from field: string agent_id = 2;
     */
    value: string;
    case: "agentId";
  } | { case: undefined; value?: undefined };
};

/**
 * Describes the message agentero.portal.RequestRefreshLicenseInfoRequest.
 * Use `create(RequestRefreshLicenseInfoRequestSchema)` to create a new message.
 */
export const RequestRefreshLicenseInfoRequestSchema: GenMessage<RequestRefreshLicenseInfoRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_compliance, 0);

/**
 * @generated from message agentero.portal.RunNIPRForAgencyRequest
 */
export type RunNIPRForAgencyRequest = Message<"agentero.portal.RunNIPRForAgencyRequest"> & {
  /**
   * @generated from field: string agency_id = 1;
   */
  agencyId: string;
};

/**
 * Describes the message agentero.portal.RunNIPRForAgencyRequest.
 * Use `create(RunNIPRForAgencyRequestSchema)` to create a new message.
 */
export const RunNIPRForAgencyRequestSchema: GenMessage<RunNIPRForAgencyRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_compliance, 1);

/**
 * @generated from message agentero.portal.RunNIPRForAgentRequest
 */
export type RunNIPRForAgentRequest = Message<"agentero.portal.RunNIPRForAgentRequest"> & {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId: string;
};

/**
 * Describes the message agentero.portal.RunNIPRForAgentRequest.
 * Use `create(RunNIPRForAgentRequestSchema)` to create a new message.
 */
export const RunNIPRForAgentRequestSchema: GenMessage<RunNIPRForAgentRequest> = /*@__PURE__*/
  messageDesc(file_agentero_portal_compliance, 2);

/**
 * @generated from service agentero.portal.ComplianceService
 */
export const ComplianceService: GenService<{
  /**
   * @generated from rpc agentero.portal.ComplianceService.RequestRefreshLicenseInfo
   */
  requestRefreshLicenseInfo: {
    methodKind: "unary";
    input: typeof RequestRefreshLicenseInfoRequestSchema;
    output: typeof EmptySchema;
  },
  /**
   * @generated from rpc agentero.portal.ComplianceService.RunNIPRForAgency
   */
  runNIPRForAgency: {
    methodKind: "unary";
    input: typeof RunNIPRForAgencyRequestSchema;
    output: typeof EmptySchema;
  },
  /**
   * @generated from rpc agentero.portal.ComplianceService.RunNIPRForAgent
   */
  runNIPRForAgent: {
    methodKind: "unary";
    input: typeof RunNIPRForAgentRequestSchema;
    output: typeof EmptySchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_agentero_portal_compliance, 0);

