import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { FieldStatus } from './fieldStatus';

export type InputProps = {
	isBig?: boolean;
	status?: FieldStatus;
};

export const defaultVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.gray.lighten80};

	&:focus {
		outline-color: ${theme.colors.primary.base};
	}
`;
export const successVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.primary.base};

	&:focus {
		outline-color: ${theme.colors.primary.base};
	}
`;
export const errorVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.error.base};

	&:focus {
		outline-color: ${theme.colors.status.error.base};
	}
`;
export const warningVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.warning.base};

	&:focus {
		outline-color: ${theme.colors.status.warning.base};
	}
`;
export const infoVariant = (theme: ITheme) => `
	border: solid 1px ${theme.colors.status.info.base};
	background-color: ${theme.colors.status.info.lighten80};

	&:focus {
		outline-color: ${theme.colors.status.info.base};
	}
`;

export const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};

type GetStatusVariant = InputProps & { theme: ITheme };

export const getStatusVariant = ({ status = FieldStatus.None, theme }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

export const Input = styled.input<InputProps>`
	outline: none;
	padding-inline: 11px;
	height: ${({ isBig = false }) => (isBig ? '48px' : '32px')};
	${({ theme, isBig = false }) => (isBig ? theme.textSettings.default : theme.textSettings.S)}
	width: 100%;
	color: ${({ theme }) => theme.colors.gray.base};
	box-sizing: border-box;
	appearance: none;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 8px;
	${getStatusVariant}

	&::placeholder {
		color: ${({ theme }) => theme.colors.gray.lighten40};
	}

	&:placeholder-shown {
		text-overflow: ellipsis;
	}

	&:focus {
		outline-offset: 0.125rem;
		outline-style: solid;
		outline-width: 0.125rem;
		${({ theme, status }) => (status ? '' : `border-color: ${theme.colors.gray.lighten80};`)}
	}

	&:disabled {
		cursor: default;
		background-color: ${({ theme }) => theme.colors.gray.lighten90};
		border: solid 1px ${({ theme }) => theme.colors.gray.lighten80};
		color: ${({ theme }) => theme.colors.gray.lighten40};
	}

	@supports (-webkit-overflow-scrolling: touch) {
		${({ theme }) => theme.textSettings.default}
	}
`;
