import { CredentialStatus } from '@agentero/grpc-clients/grpc/shared/carrier';
import { TagColor } from '@agentero/ui';

export enum CarrierCredentialsStatus {
	Enabled = 'enabled',
	MissingCredentials = 'missing_credentials'
}

export const carrierCredentialsStatusFromProtoMapping: {
	[key in CredentialStatus]: CarrierCredentialsStatus | null;
} = {
	[CredentialStatus.CREDENTIAL_STATUS_UNSPECIFIED]: null,
	[CredentialStatus.CREDENTIAL_STATUS_ENABLED]: CarrierCredentialsStatus.Enabled,
	[CredentialStatus.CREDENTIAL_STATUS_MISSING_CREDENTIALS]:
		CarrierCredentialsStatus.MissingCredentials
};

export const parseCarrierCredentialsStatusFromProto = (
	status: CredentialStatus
): CarrierCredentialsStatus => carrierCredentialsStatusFromProtoMapping[status]!;

const statusDataFromCarrierCredentialStatus: {
	[key in CarrierCredentialsStatus]: { color: TagColor; label: string };
} = {
	[CarrierCredentialsStatus.Enabled]: {
		color: 'green',
		label: 'enabled'
	},
	[CarrierCredentialsStatus.MissingCredentials]: {
		color: 'gray',
		label: 'missingCredentials'
	}
};

export const getDataFromCarrierCredentialStatus = (status: CarrierCredentialsStatus) =>
	statusDataFromCarrierCredentialStatus[status];
