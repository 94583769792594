import { PropsWithChildren } from 'react';

import { IconClose } from '@agentero/icons';
import { Box, HStack } from '@agentero/styles/jsx';

import { Button } from '../Button';
import { useAgDialog } from '../Dialog';
import { useAgModal } from '../Modal';
import { Text } from '../Text';

export const Title = ({ children }: PropsWithChildren) => {
	const { titleProps } = useAgDialog();
	const { variant, state } = useAgModal();

	return (
		<HStack
			justifyContent="space-between"
			alignItems="start"
			gap="8"
			paddingInline="var(--padding)">
			<Text as="h3" size="title.subsection" {...titleProps}>
				<b>{children}</b>
			</Text>
			{variant === 'dialog' && (
				<Box marginBlockStart="-8" marginInlineEnd="-8">
					<Button variant="ghost" onClick={state.close}>
						<IconClose />
					</Button>
				</Box>
			)}
		</HStack>
	);
};
