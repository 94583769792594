// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/tier/tier.proto (package agentero.tier, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/tier/tier.proto.
 */
export const file_agentero_tier_tier: GenFile = /*@__PURE__*/
  fileDesc("ChhhZ2VudGVyby90aWVyL3RpZXIucHJvdG8SDWFnZW50ZXJvLnRpZXIiIQoFUmFuZ2USDAoEZnJvbRgBIAEoBRIKCgJ0bxgCIAEoBSKMAgoPVGllckRlc2NyaXB0aW9uEiEKBHRpZXIYASABKA4yEy5hZ2VudGVyby50aWVyLlRpZXISLgoQcHJlbWl1bV9pbl9mb3JjZRgCIAEoCzIULmFnZW50ZXJvLnRpZXIuUmFuZ2USKgoMbmV3X2J1c2luZXNzGAMgASgLMhQuYWdlbnRlcm8udGllci5SYW5nZRIlCgdyZW5ld2FsGAQgASgLMhQuYWdlbnRlcm8udGllci5SYW5nZRIUCgxjaGFyZ2ViZWVfaWQYBSABKAkSGgoSc3Vic2NyaXB0aW9uX3ByaWNlGAYgASgCEiEKGWFubnVhbF9zdWJzY3JpcHRpb25fcHJpY2UYByABKAIiigMKC0N1cnJlbnRUaWVyEiEKBHRpZXIYASABKA4yEy5hZ2VudGVyby50aWVyLlRpZXISEAoIaXNfdHJpYWwYAiABKAgSFwoPdHJpYWxfZGF5c19sZWZ0GAMgASgFEh0KFWhhc19zb2xkX2ZpcnN0X3BvbGljeRgEIAEoCBI3ChNtaWdyYXRpb25fYWN0aXZlX2F0GAUgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBI6CgljYW1wYWlnbnMYBiADKAsyJy5hZ2VudGVyby50aWVyLkN1cnJlbnRUaWVyLkNhbXBhaWduSW5mbxI3Cg1wYXltZW50X3N0YXRlGAcgASgOMhsuYWdlbnRlcm8udGllci5QYXltZW50U3RhdGVIAIgBARIXCg9pc19iYXNlZF9vbl9waWYYCCABKAgaNQoMQ2FtcGFpZ25JbmZvEiUKBG5hbWUYASABKA4yFy5hZ2VudGVyby50aWVyLkNhbXBhaWduQhAKDl9wYXltZW50X3N0YXRlKk8KBFRpZXISFAoQVElFUl9VTlNQRUNJRklFRBAAEg4KClRJRVJfQkFTSUMQARIRCg1USUVSX1NUQU5EQVJEEAISDgoKVElFUl9FTElURRADKrEBChVUaWVyUXVhbGlmaWNhdGlvbk1vZGUSJwojVElFUl9RVUFMSUZJQ0FUSU9OX01PREVfVU5TUEVDSUZJRUQQABIoCiRUSUVSX1FVQUxJRklDQVRJT05fTU9ERV9TVUJTQ1JJUFRJT04QARIfChtUSUVSX1FVQUxJRklDQVRJT05fTU9ERV9QSUYQAhIkCiBUSUVSX1FVQUxJRklDQVRJT05fTU9ERV9QT0xJQ0lFUxADKmQKCENhbXBhaWduEhgKFENBTVBBSUdOX1VOU1BFQ0lGSUVEEAASHwobQ0FNUEFJR05fVFdPX1BPTElDSUVTX01PTlRIEAESHQoZQ0FNUEFJR05fVFdPX01PTlRIU19HUkFDRRACKmQKDFBheW1lbnRTdGF0ZRIdChlQQVlNRU5UX1NUQVRFX1VOU1BFQ0lGSUVEEAASGwoXUEFZTUVOVF9TVEFURV9TVUNDRUVERUQQARIYChRQQVlNRU5UX1NUQVRFX0ZBSUxFRBACYgZwcm90bzM", [file_google_protobuf_timestamp]);

/**
 * Range is a range of values.
 *
 * @generated from message agentero.tier.Range
 */
export type Range = Message<"agentero.tier.Range"> & {
  /**
   * @generated from field: int32 from = 1;
   */
  from: number;

  /**
   * @generated from field: int32 to = 2;
   */
  to: number;
};

/**
 * Describes the message agentero.tier.Range.
 * Use `create(RangeSchema)` to create a new message.
 */
export const RangeSchema: GenMessage<Range> = /*@__PURE__*/
  messageDesc(file_agentero_tier_tier, 0);

/**
 * TierDescription describes the requirements that have to be
 * attained to reach a tier.
 *
 * @generated from message agentero.tier.TierDescription
 */
export type TierDescription = Message<"agentero.tier.TierDescription"> & {
  /**
   * @generated from field: agentero.tier.Tier tier = 1;
   */
  tier: Tier;

  /**
   * premium_in_force is the amount of premium in force that has to be attained
   *
   * @generated from field: agentero.tier.Range premium_in_force = 2;
   */
  premiumInForce?: Range;

  /**
   * @generated from field: agentero.tier.Range new_business = 3;
   */
  newBusiness?: Range;

  /**
   * @generated from field: agentero.tier.Range renewal = 4;
   */
  renewal?: Range;

  /**
   * The id of the subscription plan in ChargeBee for this tier.
   *
   * @generated from field: string chargebee_id = 5;
   */
  chargebeeId: string;

  /**
   * The price to pay to subscribe to this tier.
   *
   * @generated from field: float subscription_price = 6;
   */
  subscriptionPrice: number;

  /**
   * Annual price to pay to subscribe to this tier.
   *
   * @generated from field: float annual_subscription_price = 7;
   */
  annualSubscriptionPrice: number;
};

/**
 * Describes the message agentero.tier.TierDescription.
 * Use `create(TierDescriptionSchema)` to create a new message.
 */
export const TierDescriptionSchema: GenMessage<TierDescription> = /*@__PURE__*/
  messageDesc(file_agentero_tier_tier, 1);

/**
 * @generated from message agentero.tier.CurrentTier
 */
export type CurrentTier = Message<"agentero.tier.CurrentTier"> & {
  /**
   * @generated from field: agentero.tier.Tier tier = 1;
   */
  tier: Tier;

  /**
   * @generated from field: bool is_trial = 2;
   */
  isTrial: boolean;

  /**
   * @generated from field: int32 trial_days_left = 3;
   */
  trialDaysLeft: number;

  /**
   * @generated from field: bool has_sold_first_policy = 4;
   */
  hasSoldFirstPolicy: boolean;

  /**
   * @generated from field: google.protobuf.Timestamp migration_active_at = 5;
   */
  migrationActiveAt?: Timestamp;

  /**
   * @generated from field: repeated agentero.tier.CurrentTier.CampaignInfo campaigns = 6;
   */
  campaigns: CurrentTier_CampaignInfo[];

  /**
   * @generated from field: optional agentero.tier.PaymentState payment_state = 7;
   */
  paymentState?: PaymentState;

  /**
   * @generated from field: bool is_based_on_pif = 8;
   */
  isBasedOnPif: boolean;
};

/**
 * Describes the message agentero.tier.CurrentTier.
 * Use `create(CurrentTierSchema)` to create a new message.
 */
export const CurrentTierSchema: GenMessage<CurrentTier> = /*@__PURE__*/
  messageDesc(file_agentero_tier_tier, 2);

/**
 * @generated from message agentero.tier.CurrentTier.CampaignInfo
 */
export type CurrentTier_CampaignInfo = Message<"agentero.tier.CurrentTier.CampaignInfo"> & {
  /**
   * @generated from field: agentero.tier.Campaign name = 1;
   */
  name: Campaign;
};

/**
 * Describes the message agentero.tier.CurrentTier.CampaignInfo.
 * Use `create(CurrentTier_CampaignInfoSchema)` to create a new message.
 */
export const CurrentTier_CampaignInfoSchema: GenMessage<CurrentTier_CampaignInfo> = /*@__PURE__*/
  messageDesc(file_agentero_tier_tier, 2, 0);

/**
 * Tier is an enum with the all the possible tiers.
 * TIER_UNSPECIFIED should be used when no tier information is available.
 *
 * @generated from enum agentero.tier.Tier
 */
export enum Tier {
  /**
   * @generated from enum value: TIER_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TIER_BASIC = 1;
   */
  BASIC = 1,

  /**
   * @generated from enum value: TIER_STANDARD = 2;
   */
  STANDARD = 2,

  /**
   * @generated from enum value: TIER_ELITE = 3;
   */
  ELITE = 3,
}

/**
 * Describes the enum agentero.tier.Tier.
 */
export const TierSchema: GenEnum<Tier> = /*@__PURE__*/
  enumDesc(file_agentero_tier_tier, 0);

/**
 * TierQualificationMode tells how a tier was attained.
 *
 * @generated from enum agentero.tier.TierQualificationMode
 */
export enum TierQualificationMode {
  /**
   * @generated from enum value: TIER_QUALIFICATION_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * TIER_QUALIFICATION_MODE_SUBSCRIPTION is for when a tier is attained by
   * a paying subscription.
   *
   * @generated from enum value: TIER_QUALIFICATION_MODE_SUBSCRIPTION = 1;
   */
  SUBSCRIPTION = 1,

  /**
   * TIER_QUALIFICATION_MODE_PIF is for when a tier is attained by
   * a certain volume of Premium in Force.
   *
   * @generated from enum value: TIER_QUALIFICATION_MODE_PIF = 2;
   */
  PIF = 2,

  /**
   * TIER_QUALIFICATION_MODE_POLICIES is for when a tier is attained by
   * a certain volume of policies sold.
   *
   * @generated from enum value: TIER_QUALIFICATION_MODE_POLICIES = 3;
   */
  POLICIES = 3,
}

/**
 * Describes the enum agentero.tier.TierQualificationMode.
 */
export const TierQualificationModeSchema: GenEnum<TierQualificationMode> = /*@__PURE__*/
  enumDesc(file_agentero_tier_tier, 1);

/**
 * Campaign is an enum with the all the possible campaigns.
 *
 * @generated from enum agentero.tier.Campaign
 */
export enum Campaign {
  /**
   * @generated from enum value: CAMPAIGN_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CAMPAIGN_TWO_POLICIES_MONTH = 1;
   */
  TWO_POLICIES_MONTH = 1,

  /**
   * @generated from enum value: CAMPAIGN_TWO_MONTHS_GRACE = 2;
   */
  TWO_MONTHS_GRACE = 2,
}

/**
 * Describes the enum agentero.tier.Campaign.
 */
export const CampaignSchema: GenEnum<Campaign> = /*@__PURE__*/
  enumDesc(file_agentero_tier_tier, 2);

/**
 * PaymentState is an enum with the all the possible payments.
 *
 * @generated from enum agentero.tier.PaymentState
 */
export enum PaymentState {
  /**
   * @generated from enum value: PAYMENT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PAYMENT_STATE_SUCCEEDED = 1;
   */
  SUCCEEDED = 1,

  /**
   * @generated from enum value: PAYMENT_STATE_FAILED = 2;
   */
  FAILED = 2,
}

/**
 * Describes the enum agentero.tier.PaymentState.
 */
export const PaymentStateSchema: GenEnum<PaymentState> = /*@__PURE__*/
  enumDesc(file_agentero_tier_tier, 3);

