import { DetailedHTMLProps, InputHTMLAttributes, KeyboardEvent, forwardRef } from 'react';

import { Input, InputVariants } from '../fieldText/Input';

type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type InputIntegerProps = HTMLInputProps & InputVariants;

export const InputInteger = forwardRef<HTMLInputElement, InputIntegerProps>((props, ref) => {
	const avoidNegative = (event: KeyboardEvent<HTMLInputElement>) => {
		if (['-', 'e', '.', ','].includes(event.key)) {
			event.preventDefault();
		}

		//@ts-ignore
		const valueAsNumber = event.target.valueAsNumber;
		const value = isNaN(valueAsNumber) ? event.key : `${valueAsNumber}${event.key}`;

		if (props.max && (props.max as number) < Number(value)) {
			event.preventDefault();
		}
	};

	return (
		<Input type="number" min={props.min ?? 0} ref={ref} {...props} onKeyPress={avoidNegative} />
	);
});
