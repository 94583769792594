import { PropsWithChildren } from 'react';

import { cva } from '@agentero/styles/css';

const dataTableRowActionsStyles = cva({
	base: {
		display: 'flex',
		gap: '4',
		width: 'fit-content',
		'& [data-slot="button"]': {
			opacity: 0,
			transition: 'opacity 0.15s'
		}
	}
});

export const TableRowActions = ({ children }: PropsWithChildren) => (
	<div data-slot="table-row-actions" className={dataTableRowActionsStyles()}>
		{children}
	</div>
);
