import { PropsWithChildren } from 'react';

import { RecipeVariantProps, cva } from '@agentero/styles/css';

// TODO: Review styles and variants when NEXT_PUBLIC_REDESIGN_FEATURE flag is removed. Consider using a slot recipe for all data table components.
const dataTableRootStyles = cva({
	base: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		minHeight: '0'
	},
	variants: {
		embed: {
			false: {
				gap: '24'
			}
		},
		layout: {
			sidebar: {
				alignItems: 'flex-start',
				flexDirection: 'row'
			},
			toolbar: {
				flexDirection: 'column'
			}
		}
	},
	defaultVariants: {
		embed: false,
		layout: 'sidebar'
	}
});

type DataTableRootProps = RecipeVariantProps<typeof dataTableRootStyles>;

export const DataTableRoot = ({ children, ...props }: PropsWithChildren<DataTableRootProps>) => (
	<div data-slot="data-table-root" className={dataTableRootStyles(props)}>
		{children}
	</div>
);
