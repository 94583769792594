'use client';

import { DOMAttributes, ReactNode, RefObject, createContext, useContext, useRef } from 'react';

import { AriaButtonProps } from '@react-aria/button';
import { AriaModalOverlayProps, useModalOverlay, useOverlayTrigger } from '@react-aria/overlays';
import {
	OverlayTriggerProps,
	OverlayTriggerState,
	useOverlayTriggerState
} from '@react-stately/overlays';
import { DOMProps, FocusableElement } from '@react-types/shared';

import { Body } from './modal/Body';
import { Content, ModalContentVariantProps } from './modal/Content';
import { Footer } from './modal/Footer';
import { Title } from './modal/Title';
import { Trigger } from './modal/Trigger';

type ModalRootChildrenProps = {
	close: () => void;
};

type ModalVariant = 'alert' | 'dialog';
type ModalProps = OverlayTriggerProps &
	AriaModalOverlayProps & {
		children: ReactNode | ((props: ModalRootChildrenProps) => ReactNode);
		variant?: ModalVariant;
		size?: NonNullable<ModalContentVariantProps>['size'];
	};

type ModalContextValue = {
	triggerProps: AriaButtonProps<'button'>;
	overlayProps: DOMProps;
	underlayProps: DOMAttributes<FocusableElement>;
	modalProps: DOMAttributes<FocusableElement>;
	modalRef: RefObject<HTMLDivElement>;
	state: OverlayTriggerState;
	variant: ModalVariant;
	size?: NonNullable<ModalContentVariantProps>['size'];
};

const ModalContext = createContext<ModalContextValue>({
	triggerProps: {},
	underlayProps: {},
	modalProps: {},
	overlayProps: {},
	modalRef: { current: null as unknown as HTMLDivElement },
	state: {
		isOpen: false,
		open: () => {},
		close: () => {},
		toggle: () => {},
		setOpen: () => {}
	},
	variant: 'dialog'
});

export const useAgModal = () => useContext(ModalContext);

export const Root = ({ children, variant = 'dialog', size, ...props }: ModalProps) => {
	const modalRef = useRef<HTMLDivElement>(null!);
	const state = useOverlayTriggerState(props);
	const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

	const { modalProps, underlayProps } = useModalOverlay(
		{ ...props, isDismissable: variant === 'dialog' },
		state,
		modalRef
	);

	const childrenProps: ModalRootChildrenProps = {
		close: state.close
	};

	return (
		<ModalContext.Provider
			value={{
				triggerProps,
				underlayProps,
				state,
				modalProps,
				overlayProps,
				modalRef,
				variant,
				size
			}}>
			{typeof children === 'function' ? children(childrenProps) : children}
		</ModalContext.Provider>
	);
};

export const Modal = {
	Root,
	Content,
	Trigger,
	Title,
	Body,
	Footer
};
