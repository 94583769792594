import { RecipeVariantProps, cva } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';

export const inputRecipe = cva({
	base: {
		outline: 'none',
		paddingInline: '11px',
		width: '100%',
		boxSizing: 'border-box',
		appearance: 'none',
		borderRadius: 'md',
		color: 'neutral',
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: '1px',
		boxShadow: '1',
		_placeholder: {
			color: 'neutral.50'
		},
		_placeholderShown: {
			textOverflow: 'ellipsis'
		},
		_focus: {
			outlineOffset: '0.125rem',
			outlineStyle: 'solid',
			outlineWidth: '0.125rem'
		},
		_disabled: {
			cursor: 'default',
			backgroundColor: 'neutral.90',
			borderColor: 'neutral.80',
			color: 'neutral.50'
		},
		'@supports (-webkit-overflow-scrolling: touch)': {
			textStyle: 'body.base'
		}
	},
	variants: {
		size: {
			sm: {
				height: '2rem',
				textStyle: 'body.small'
			},
			md: {
				height: '2.5rem',
				textStyle: 'body.small'
			},
			lg: {
				height: '3rem',
				textStyle: 'body.base',
				paddingInline: '15px',
				borderRadius: 'lg'
			}
		},
		status: {
			none: {
				borderColor: 'border.input.default',
				_focus: {
					outlineColor: 'brand'
				}
			},
			success: {
				borderColor: 'brand',
				_focus: {
					outlineColor: 'brand'
				}
			},
			error: {
				borderColor: 'red',
				_focus: {
					outlineColor: 'red'
				}
			},
			warning: {
				borderColor: 'yellow',
				_focus: {
					outlineColor: 'yellow'
				}
			},
			info: {
				borderColor: 'blue',
				_focus: {
					outlineColor: 'blue'
				}
			}
		}
	},
	defaultVariants: {
		size: 'sm',
		status: 'none'
	}
});

export const Input = styled('input', inputRecipe);

export type InputVariants = RecipeVariantProps<typeof inputRecipe>;

export type InputSize = NonNullable<InputVariants>['size'];
