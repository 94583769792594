import styled from '@emotion/styled';

type LoadingDotsProps = {
	size?: 'S' | 'L';
};

const LoadingDotsContainer = styled.div<LoadingDotsProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${({ size }) => (size === 'S' ? 4 : 24)}px;
`;

const LoadingDot = styled.span<LoadingDotsProps>`
	width: ${({ size }) => (size === 'S' ? 6 : 12)}px;
	height: ${({ size }) => (size === 'S' ? 6 : 12)}px;
	background: ${({ theme }) => theme.colors.primary.base};
	border-radius: 50%;
	animation: dot-fade 1s infinite cubic-bezier(0.65, 0, 0.35, 1);

	&:nth-of-type(2) {
		animation-delay: 0.1s;
	}

	&:nth-of-type(3) {
		animation-delay: 0.2s;
	}

	@keyframes dot-fade {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;

export const LoadingDots = ({ size = 'S' }: LoadingDotsProps) => (
	<LoadingDotsContainer size={size}>
		{Array.from({ length: 3 }).map((_, index) => (
			<LoadingDot key={index} size={size} />
		))}
	</LoadingDotsContainer>
);
