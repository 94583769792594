//TODO: Remove when https://github.com/tannerlinsley/@tanstack/react-query/issues/1503 is fixed
import { Suspense } from 'react';

// eslint-disable-next-line @emotion/no-vanilla
import { EmotionCache } from '@emotion/react';
import { DehydratedState } from '@tanstack/react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Agent, isAgent } from '@agentero/models';
import { createAgenteroCache } from '@agentero/styles/emotion';
import '@agentero/styles/global.css';
import { Flex } from '@agentero/styles/jsx';

import { Ag } from 'modules/Ag';
import { ProducerIdAccessModalTrigger } from 'modules/producerId/ProducerIdAccessModalTrigger';
import { Providers } from 'modules/providers';
import { URLProvider } from 'modules/shared/UrlContext';
import { TierModalPaymentFailed } from 'modules/tiersAndBilling/TierModalPaymentFailed';
import { HubspotChat } from 'packages/analytics/HubspotChat';
import { AgentUniqueIdentifierContextProvider } from 'packages/contexts/AgentUniqueIdentifierContext';

//@ts-ignore
global.XMLHttpRequest = require('xhr2');

//@ts-ignore
if (typeof global.navigator === 'undefined') global.navigator = {}; //TODO: Remove when https://github.com/tannerlinsley/@tanstack/react-query/issues/1503 is fixed

type AgenteroAppProps = {
	agent: Agent;
	agentUniqueIdentifier: string;
	dehydratedState: DehydratedState;
};

type Props = NextPage<AppProps<AgenteroAppProps> & { emotionCache: EmotionCache }, {}>;

const clientSideCache = createAgenteroCache();

const AgenteroApp: Props = props => {
	const { Component, pageProps, emotionCache = clientSideCache } = props;
	const { agent, agentUniqueIdentifier } = pageProps;
	const { query } = useRouter();

	return (
		<AgentUniqueIdentifierContextProvider agentUniqueIdentifier={agentUniqueIdentifier}>
			<Head>
				<title>Agentero App</title>
			</Head>
			<Providers dehydratedState={pageProps.dehydratedState} emotionCache={emotionCache}>
				<URLProvider>
					<Suspense
						fallback={
							<Flex flex="1" justifyContent="center" alignItems="center">
								<Ag />
							</Flex>
						}>
						<Component {...pageProps} />
						{agent && <TierModalPaymentFailed />}
						{agent && (
							<Suspense>
								<ProducerIdAccessModalTrigger />
							</Suspense>
						)}
					</Suspense>
				</URLProvider>
				{agent && isAgent(agent) && (
					<Suspense fallback={null}>{!query.binderId && <HubspotChat />}</Suspense>
				)}
			</Providers>
		</AgentUniqueIdentifierContextProvider>
	);
};

export default AgenteroApp;
