import { ProducerID_Status as ProducerIdStatusProto } from '@agentero/service-clients/portal/session';

export enum ProducerIdStatus {
	// The agent is unavailable because the agency is not elite or is not the principal
	Unavailable = 'unavailable',
	// The agent is available because the agency is elite, is the principal and has not onboarded to Producerflow
	Available = 'available',
	// The agent is available because the agency is elite, is the principal and has onboarded to Producerflow
	Onboarded = 'onboarded'
}

const map = {
	[ProducerIdStatusProto.UNSPECIFIED]: ProducerIdStatus.Unavailable,
	[ProducerIdStatusProto.AVAILABLE]: ProducerIdStatus.Available,
	[ProducerIdStatusProto.ONBOARDED]: ProducerIdStatus.Onboarded
};

export const parseProducerIdStatusFromProto = (status: ProducerIdStatusProto) => map[status];

export const isProducerIdAvailable = (producerIdStatus: ProducerIdStatus): boolean =>
	producerIdStatus === ProducerIdStatus.Available ||
	producerIdStatus === ProducerIdStatus.Onboarded;
