import { ChangeEvent, useState } from 'react';

import { Path, PathValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Card, useAgFormContext } from '@agentero/ui';

import { Divider } from '../../Divider';
import { Grid } from '../../Grid';
import { GridItem } from '../../grid/GridItem';
import { Legend } from '../Legend';
import { FormGroupInput } from './FormGroupInput';
import { SelectValue } from './FormGroupSelect';
import { FormGroupVINRelatedInput } from './formGroupVIN/FormGroupVINRelatedInput';

type FormGroupVINFieldProps<T> = {
	name: Path<T>;
};

type FormGroupInputVINRelatedProps<T> = FormGroupVINFieldProps<T> & {
	options: SelectValue[];
};

type DecodedVIN = { year: string; make: string; model: string };

type FormGroupInputVINProps<T> = {
	isComplete: boolean;
	number: FormGroupVINFieldProps<T>;
	year: FormGroupInputVINRelatedProps<T>;
	make: FormGroupInputVINRelatedProps<T>;
	model: FormGroupInputVINRelatedProps<T>;
	decodeVIN: (VIN: string) => Promise<DecodedVIN>;
};

const VIN_MAX_LENGTH = 17;

export const FormGroupVIN = <T extends {}>({
	isComplete,
	number,
	year,
	make,
	model,
	decodeVIN
}: FormGroupInputVINProps<T>) => {
	const { t } = useTranslation('inputVIN');
	const {
		getValues,
		clearErrors,
		setError,
		setValue,
		formState: { isSubmitted },
		isReadonly
	} = useAgFormContext<T>();

	const [isVINSearched, setIsVINSearched] = useState(!!getValues(number.name));

	const setVINRelatedValues = (values: DecodedVIN) => {
		const fields = [
			{ name: year.name, value: values.year },
			{ name: make.name, value: values.make },
			{ name: model.name, value: values.model }
		];

		fields.map(({ name, value }) => {
			setValue(name, value as PathValue<T, Path<T>>, {
				shouldValidate: isSubmitted,
				shouldDirty: true
			});
		});
	};

	const onNumberChange = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length === VIN_MAX_LENGTH) {
			try {
				const values = await decodeVIN(getValues(number.name));
				setIsVINSearched(true);
				clearErrors([number.name, year.name, make.name, model.name]);
				setVINRelatedValues(values);
			} catch (error) {
				console.error(`Error when decoding VIN on number change: ${error}`);
				setIsVINSearched(false);
				setError(number.name, { message: t('VINIncomplete') });
			}

			return;
		}

		isVINSearched && setVINRelatedValues({ year: '', make: '', model: '' });
		setIsVINSearched(false);
		(getValues(number.name) as string).length === 0 && clearErrors(number.name);
	};

	return (
		<Card role="fieldset">
			<div>
				<Legend>VIN</Legend>
				<FormGroupInput<T>
					name={number.name}
					maxLength={VIN_MAX_LENGTH}
					label={t('number')}
					onChange={onNumberChange}
					optional={!isComplete}
					autoFocus
				/>

				{!isComplete && !isReadonly && <Divider label="OR" />}

				<Grid spacing={16} style={{ marginBottom: -16 }}>
					<GridItem sm={4}>
						<FormGroupVINRelatedInput<T>
							name={year.name}
							label={t('year')}
							options={year.options}
							isReadOnly={isComplete || isVINSearched}
							onChange={() =>
								[make.name, model.name].map(name => setValue(name, '' as PathValue<T, Path<T>>))
							}
						/>
					</GridItem>

					<GridItem sm={4}>
						<FormGroupVINRelatedInput<T>
							name={make.name}
							label={t('make')}
							disabled={make.options.length === 0}
							options={make.options}
							isReadOnly={isComplete || isVINSearched}
							onChange={() => setValue(model.name, '' as PathValue<T, Path<T>>)}
						/>
					</GridItem>

					<GridItem sm={4}>
						<FormGroupVINRelatedInput<T>
							name={model.name}
							label={t('model')}
							disabled={model.options.length === 0}
							options={model.options}
							isReadOnly={isComplete || isVINSearched}
						/>
					</GridItem>
				</Grid>
			</div>
		</Card>
	);
};
