import { useEffect, useState } from 'react';

import { parseDate } from '@internationalized/date';
import { DateRange } from '@react-aria/datepicker';

import { Flex, Stack } from '@agentero/styles/jsx';

import { Button } from '../../../../Button';
import { DatePickerRange } from '../../../../inputs/DatePickerRange';
import { BaseSearch } from '../../../DataTableFilters';
import { DateRangeFilterProps, DateRangeState } from '../../shared/DateRangeFilter';

export const SidebarDateRangeFilter = <Search extends BaseSearch>({
	filter,
	search,
	onSearchChange,
	isClearable
}: DateRangeFilterProps<Search>) => {
	const rangeState = search[filter.key] as DateRangeState;

	const state = rangeState
		? { start: parseDate(rangeState.start), end: parseDate(rangeState.end) }
		: null;

	const [value, setValue] = useState<DateRange | null>(state);

	const setVal = (values: DateRange | null) => {
		setValue(values);
	};

	const onChange = (values: DateRange | null) => {
		if (!values) {
			const newSearch = { ...search, [filter.key]: undefined };
			onSearchChange(newSearch);
			return;
		}
		const state: DateRangeState = {
			start: values.start.toString(),
			end: values.end.toString()
		};

		const newSearch = { ...search, [filter.key]: state };
		onSearchChange(newSearch);
	};

	useEffect(() => {
		if (value !== state) {
			onChange(value);
		}
	}, [value]);

	const clearFilter = () => {
		setValue(null);
	};

	return (
		<Stack paddingBlockStart="4">
			<DatePickerRange onChange={setVal} aria-label={filter.label} value={value} />
			{isClearable && (
				<Flex>
					<Button variant="link" onClick={clearFilter}>
						Clear filter
					</Button>
				</Flex>
			)}
		</Stack>
	);
};
