import { AriaRole, Children, PropsWithChildren } from 'react';

import styled from '@emotion/styled';

type SpaceSpacing = number | { vertical: number; horizontal: number };

type SpaceAlign = 'start' | 'center' | 'end' | 'unset';

type SpaceProps = {
	direction?: 'row' | 'column';
	align?: SpaceAlign;
	alignItems?: SpaceAlign;
	spacing?: SpaceSpacing;
	className?: string;
	role?: AriaRole;
};

type SpaceContainerProps = Required<Omit<SpaceProps, 'className' | 'role'>> &
	Pick<SpaceProps, 'className'>;

type SpaceItemProps = Required<Pick<SpaceProps, 'spacing' | 'direction'>>;

const SpaceContainer = styled.ul<SpaceContainerProps>`
	display: flex;
	flex-direction: ${({ direction }) => direction};
	justify-content: ${({ align }) => (align !== 'center' ? `flex-${align}` : align)};
	align-items: ${({ alignItems }) =>
		['center', 'unset'].includes(alignItems) ? alignItems : `flex-${alignItems}`};
	flex-wrap: wrap;
	margin: ${({ spacing }) =>
		typeof spacing === 'number'
			? `${spacing * -1}px`
			: `${spacing.vertical * -1}px ${spacing.horizontal * -1}px`};
`;

const SpaceItem = styled.li<SpaceItemProps>`
	margin-block: ${({ spacing }) =>
		typeof spacing === 'number' ? `${spacing}px` : `${spacing.vertical}px`};
	${({ spacing }) =>
		typeof spacing === 'number'
			? `${spacing >= 0 ? 'padding' : 'margin'}-inline: ${spacing}px`
			: `${spacing.horizontal >= 0 ? 'padding' : 'margin'}-inline: ${spacing.horizontal}px`};
	${({ direction, spacing }) =>
		direction === 'column' && typeof spacing === 'number' && `width: fill-available`};
	${({ direction }) => direction === 'row' && `display: flex; align-items: center;`}

	&:empty {
		display: none;
	}
`;

/**
 * @deprecated Use **'@agentero/styles/jsx'** *Stack*, *VStack*, *HStack* or *Wrapper* components depending on the case instead.
 */
export const Space = ({
	direction = 'row',
	align = 'start',
	alignItems = 'unset',
	spacing = 8,
	className,
	children,
	role
}: PropsWithChildren<SpaceProps>) => (
	<SpaceContainer
		direction={direction}
		align={align}
		alignItems={alignItems}
		spacing={spacing}
		className={className}
		role={role}
		as={role === 'none' ? 'div' : undefined}>
		{Children.map(
			children,
			child =>
				child &&
				// @ts-ignore
				(child?.props?.isVisible === true || child?.props?.isVisible === undefined) && (
					<SpaceItem
						spacing={spacing}
						direction={direction}
						as={role === 'none' ? 'div' : undefined}>
						{child}
					</SpaceItem>
				)
		)}
	</SpaceContainer>
);
