import { PropsWithChildren } from 'react';

import { getInputStatusFromError } from '@agentero/utils';

import { useAgFormContext } from '../Form';
import { Field, FieldGenericProps } from '../shared/Field';
import { getFieldError, getFieldErrorId } from '../shared/formGroupUtils';
import { RadioChips, RadioChipsValue } from './fieldRadioChips/RadioChips';
import { InputVariants } from './fieldText/Input';

type FieldTextProps<T> = {
	onGroupChange?: (value: string | boolean) => void;
	values: RadioChipsValue[];
	defaultValue?: string | boolean;
} & FieldGenericProps<T> &
	InputVariants;

//TODO: Check point 6 from : https://adrianroselli.com/2022/02/support-for-marking-radio-buttons-required-invalid.html

export const FieldRadioChips = <T extends {}>({
	name,
	defaultValue,
	onGroupChange,
	children,
	values,
	...formGroupProps
}: PropsWithChildren<FieldTextProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<Field
			name={name}
			nestedChildren={children}
			asFieldset
			getReadonlyValue={inputValue =>
				values?.find(option => option.value === inputValue)?.label ?? '—'
			}
			{...formGroupProps}>
			<RadioChips
				inputProps={{
					...register(name),
					'aria-errormessage': getFieldErrorId(name),
					'aria-invalid': !!fieldError?.message,
					disabled: formGroupProps.disabled
				}}
				status={getInputStatusFromError(fieldError?.message as string)}
				values={values}
				onChange={onGroupChange}
				defaultValue={defaultValue}
			/>
		</Field>
	);
};
