import { currentExpertResourceKey } from 'packages/services/fetch/back-ag/useAgentResource';

import { MutationConfig, UseAgMutationCallbacks, useAgMutation } from '../../useAgMutation';
import { onboardAgencyToProducerId } from './onboardAgencyToProducerId';

type AgencyOnboardProducerIdMutationConfig = MutationConfig<{}, void>;

const onboardAgencyConfiguration: AgencyOnboardProducerIdMutationConfig = {
	mutation: async () => {
		await onboardAgencyToProducerId();
	},
	invalidateQueries: () => [[currentExpertResourceKey]]
};

export const useOnboardAgencyToProducerId = (callbacks?: UseAgMutationCallbacks) => {
	return useAgMutation(onboardAgencyConfiguration, callbacks);
};
