import { IconError } from '@agentero/icons';
import { styled } from '@agentero/styles/jsx';

type FormErrorProps = {
	id?: string;
	error?: string;
	className?: string;
};

const FormErrorContainer = styled('div', {
	base: {
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: '.5rem',
		color: 'red',
		textStyle: 'caption.base',
		'& svg': {
			width: '1rem',
			height: '1rem',
			minWidth: '1rem',
			marginRight: '.25rem',
			'& path': {
				fill: 'red'
			}
		}
	}
});

export const FormError = ({ error, className, id }: FormErrorProps) => {
	return error ? (
		<FormErrorContainer id={id} className={className}>
			<IconError />
			{error}
		</FormErrorContainer>
	) : null;
};
