import { PropsWithChildren } from 'react';

import { RecipeVariantProps, cva } from '@agentero/styles/css';

// TODO: Review styles and variants when NEXT_PUBLIC_REDESIGN_FEATURE flag is removed. Consider using a slot recipe for all data table components.
const dataTableContentStyles = cva({
	base: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		minHeight: '0',
		minWidth: '0'
	},
	variants: {
		embed: {
			false: {
				gap: '24'
			}
		}
	},
	defaultVariants: {
		embed: false
	}
});

type DataTableContentProps = RecipeVariantProps<typeof dataTableContentStyles>;

export const DataTableContent = ({
	children,
	...props
}: PropsWithChildren<DataTableContentProps>) => (
	<div data-slot="data-table-content" className={dataTableContentStyles(props)}>
		{children}
	</div>
);
