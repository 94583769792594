// @generated by protoc-gen-es v2.2.3 with parameter "target=ts,import_extension=none"
// @generated from file agentero/binding/binding.proto (package agentero.binding, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file agentero/binding/binding.proto.
 */
export const file_agentero_binding_binding: GenFile = /*@__PURE__*/
  fileDesc("Ch5hZ2VudGVyby9iaW5kaW5nL2JpbmRpbmcucHJvdG8SEGFnZW50ZXJvLmJpbmRpbmci5AMKC0JpbmRpbmdEYXRhEi8KCmFnZW50X2RhdGEYASABKAsyGy5hZ2VudGVyby5iaW5kaW5nLkFnZW50RGF0YRIUCgxwaG9uZV9udW1iZXIYAiABKAkSDQoFZW1haWwYAyABKAkSEQoJZnVsbF9uYW1lGAQgASgJEhEKCXJvb2ZfeWVhchgFIAEoCRIxCg1wdXJjaGFzZV9kYXRlGAYgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBI7ChRkZXNpcmVkX3BheW1lbnRfcGxhbhgHIAEoDjIdLmFnZW50ZXJvLmJpbmRpbmcuUGF5bWVudFBsYW4SFAoMYWNjZXB0X3Rlcm1zGAggASgIEhUKDWZpbmFsX3ByZW1pdW0YCSABKAISMgoOZWZmZWN0aXZlX2RhdGUYCiABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wEhwKFGluY2x1ZGVfcGlwX2NvdmVyYWdlGAsgASgIEhUKDXBvbGljeV9udW1iZXIYDCABKAkSHgoWYWRkaXRpb25hbF9pbmZvcm1hdGlvbhgNIAEoCRIzCg9leHBpcmF0aW9uX2RhdGUYDiABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wIiEKCUFnZW50RGF0YRIUCgxwaG9uZV9udW1iZXIYASABKAkqhgEKC1BheW1lbnRQbGFuEhwKGFBBWU1FTlRfUExBTl9VTlNQRUNJRklFRBAAEiEKHVBBWU1FTlRfUExBTl9NT1JUR0FHRUVfQklMTEVEEAESGAoUUEFZTUVOVF9QTEFOX0VGVF9BQ0gQAhIcChhQQVlNRU5UX1BMQU5fQ1JFRElUX0NBUkQQAyqKAgoVQmluZFJlcXVlc3RTdGF0dXNDb2RlEiMKH0JJTkRfUkVRVUVTVF9TVEFUVVNfVU5TUEVDSUZJRUQQABIgChxCSU5EX1JFUVVFU1RfU1RBVFVTX1JFQ0VJVkVEEAESIQodQklORF9SRVFVRVNUX1NUQVRVU19JTl9SRVZJRVcQAhIjCh9CSU5EX1JFUVVFU1RfU1RBVFVTX0ZJTkFMX1FVT1RFEAMSHwobQklORF9SRVFVRVNUX1NUQVRVU19JU1NVSU5HEAQSHgoaQklORF9SRVFVRVNUX1NUQVRVU19JU1NVRUQQBRIhCh1CSU5EX1JFUVVFU1RfU1RBVFVTX0NBTkNFTExFRBAGYgZwcm90bzM", [file_google_protobuf_timestamp]);

/**
 * @generated from message agentero.binding.BindingData
 */
export type BindingData = Message<"agentero.binding.BindingData"> & {
  /**
   * @generated from field: agentero.binding.AgentData agent_data = 1;
   */
  agentData?: AgentData;

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber: string;

  /**
   * @generated from field: string email = 3;
   */
  email: string;

  /**
   * @generated from field: string full_name = 4;
   */
  fullName: string;

  /**
   * @generated from field: string roof_year = 5;
   */
  roofYear: string;

  /**
   * @generated from field: google.protobuf.Timestamp purchase_date = 6;
   */
  purchaseDate?: Timestamp;

  /**
   * @generated from field: agentero.binding.PaymentPlan desired_payment_plan = 7;
   */
  desiredPaymentPlan: PaymentPlan;

  /**
   * @generated from field: bool accept_terms = 8;
   */
  acceptTerms: boolean;

  /**
   * @generated from field: float final_premium = 9;
   */
  finalPremium: number;

  /**
   * @generated from field: google.protobuf.Timestamp effective_date = 10;
   */
  effectiveDate?: Timestamp;

  /**
   * @generated from field: bool include_pip_coverage = 11;
   */
  includePipCoverage: boolean;

  /**
   * @generated from field: string policy_number = 12;
   */
  policyNumber: string;

  /**
   * @generated from field: string additional_information = 13;
   */
  additionalInformation: string;

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 14;
   */
  expirationDate?: Timestamp;
};

/**
 * Describes the message agentero.binding.BindingData.
 * Use `create(BindingDataSchema)` to create a new message.
 */
export const BindingDataSchema: GenMessage<BindingData> = /*@__PURE__*/
  messageDesc(file_agentero_binding_binding, 0);

/**
 * @generated from message agentero.binding.AgentData
 */
export type AgentData = Message<"agentero.binding.AgentData"> & {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber: string;
};

/**
 * Describes the message agentero.binding.AgentData.
 * Use `create(AgentDataSchema)` to create a new message.
 */
export const AgentDataSchema: GenMessage<AgentData> = /*@__PURE__*/
  messageDesc(file_agentero_binding_binding, 1);

/**
 * @generated from enum agentero.binding.PaymentPlan
 */
export enum PaymentPlan {
  /**
   * @generated from enum value: PAYMENT_PLAN_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PAYMENT_PLAN_MORTGAGEE_BILLED = 1;
   */
  MORTGAGEE_BILLED = 1,

  /**
   * @generated from enum value: PAYMENT_PLAN_EFT_ACH = 2;
   */
  EFT_ACH = 2,

  /**
   * @generated from enum value: PAYMENT_PLAN_CREDIT_CARD = 3;
   */
  CREDIT_CARD = 3,
}

/**
 * Describes the enum agentero.binding.PaymentPlan.
 */
export const PaymentPlanSchema: GenEnum<PaymentPlan> = /*@__PURE__*/
  enumDesc(file_agentero_binding_binding, 0);

/**
 * @generated from enum agentero.binding.BindRequestStatusCode
 */
export enum BindRequestStatusCode {
  /**
   * @generated from enum value: BIND_REQUEST_STATUS_UNSPECIFIED = 0;
   */
  BIND_REQUEST_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_RECEIVED = 1;
   */
  BIND_REQUEST_STATUS_RECEIVED = 1,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_IN_REVIEW = 2;
   */
  BIND_REQUEST_STATUS_IN_REVIEW = 2,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_FINAL_QUOTE = 3;
   */
  BIND_REQUEST_STATUS_FINAL_QUOTE = 3,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_ISSUING = 4;
   */
  BIND_REQUEST_STATUS_ISSUING = 4,

  /**
   * BIND_REQUEST_STATUS_ISSUED means the policy is issued and the bind request is completed
   *
   * @generated from enum value: BIND_REQUEST_STATUS_ISSUED = 5;
   */
  BIND_REQUEST_STATUS_ISSUED = 5,

  /**
   * @generated from enum value: BIND_REQUEST_STATUS_CANCELLED = 6;
   */
  BIND_REQUEST_STATUS_CANCELLED = 6,
}

/**
 * Describes the enum agentero.binding.BindRequestStatusCode.
 */
export const BindRequestStatusCodeSchema: GenEnum<BindRequestStatusCode> = /*@__PURE__*/
  enumDesc(file_agentero_binding_binding, 1);

