import { useEffect, useRef } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import { styled } from '@agentero/styles/jsx';

import { Button } from '../../../Button';
import { FieldStatus } from '../../shared/fieldStatus';

const Container = styled('div', {
	base: {
		border: '1px solid gray',
		width: 500
	},
	variants: {
		hasError: {
			true: {
				borderColor: 'red'
			}
		}
	}
});

type InputSignatureCanvasProps = {
	defaultValue?: string;
	onChange?: (value?: string) => void;
	status?: FieldStatus;
};

export const InputSignatureCanvas = ({
	defaultValue,
	onChange,
	status
}: InputSignatureCanvasProps) => {
	const signatureCanvasRef = useRef<SignatureCanvas>(null);

	const clearCanvas = () => {
		signatureCanvasRef.current!.clear();
	};

	const loadSignatureData = (imageValue: string) => {
		signatureCanvasRef.current!.fromDataURL(imageValue);
	};

	useEffect(() => {
		if (signatureCanvasRef.current) {
			defaultValue && loadSignatureData(defaultValue);
		}
	}, [defaultValue, signatureCanvasRef]);

	return (
		<Container hasError={status === FieldStatus.Error}>
			{/* @ts-ignore -> We need to upgrade dependency @types/react-signature-canvas to make it compatible with react 19 */}
			<SignatureCanvas
				ref={signatureCanvasRef}
				penColor="black"
				canvasProps={{ width: 500, height: 200, className: 'sigCanvas', role: 'img' }}
				onEnd={() => {
					onChange && onChange(signatureCanvasRef.current!.toDataURL());
				}}
			/>
			<Button
				type="button"
				onClick={() => {
					clearCanvas();
					onChange?.(undefined);
				}}
				variant="ghost">
				Clear
			</Button>
		</Container>
	);
};
