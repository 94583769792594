import React from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { IconHorizontalDots, IconKeyboardArrowLeft, IconKeyboardArrowRight } from '@agentero/icons';
import { numberWithCommas } from '@agentero/utils';

import { Page } from './pager/Page';
import { PAGE_TYPE, getPages } from './pager/utils';

type PagerProps = {
	totalPages: number;
	actualPage: number;
	pageItems: number;
	totalItems: number;
	onPageChange: (newPage: number) => void;
};

export const PagerContainer = styled.nav`
	display: flex;
	flex-direction: column;
	align-items: center;

	${({ theme }) => theme.mediaqueries.S} {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const PagesList = styled.ul`
	display: flex;
	order: 1;

	${({ theme }) => theme.mediaqueries.S} {
		order: 2;
	}
`;

const firstPage = 1;

const Entries = styled.span`
	${props => props.theme.textSettings.XS}
	color: ${props => props.theme.colors.gray.lighten40};
	margin-top: 8px;
	order: 2;

	${({ theme }) => theme.mediaqueries.S} {
		margin-top: 0;
		order: 1;
	}
`;

export const Pager: React.FC<PagerProps> = ({
	totalPages,
	actualPage,
	pageItems,
	totalItems,
	onPageChange
}) => {
	const { t } = useTranslation('pager', { useSuspense: false });

	const isNextDisable = actualPage === totalPages;
	const isPrevDisable = actualPage === 1;
	const pages = getPages({ totalPages, actualPage });

	return (
		<PagerContainer>
			<Entries>{t('entries', { pageItems, totalItems: numberWithCommas(totalItems) })}</Entries>
			{totalPages != 0 && (
				<PagesList>
					<Page
						disabled={isPrevDisable}
						onPageChange={() => onPageChange(actualPage - 1)}
						title={t('previousPage')}>
						<IconKeyboardArrowLeft />
					</Page>

					<Page
						isSelected={actualPage === firstPage}
						onPageChange={() => onPageChange(firstPage)}
						disabled={actualPage === firstPage}
						title={t('goToPage', { page: firstPage })}>
						{firstPage}
					</Page>
					{/*TODO: The key have to be the page instead index but we have to fix the animation*/}
					{pages.map(({ page, type }, index) => (
						<Page
							key={index}
							isSelected={actualPage === page}
							disabled={actualPage === page}
							onPageChange={() => onPageChange(page)}
							title={t('goToPage', { page })}>
							{type === PAGE_TYPE.NORMAL ? page : <IconHorizontalDots />}
						</Page>
					))}
					{totalPages !== 1 && (
						<Page
							isSelected={actualPage === totalPages}
							disabled={actualPage === totalPages}
							onPageChange={() => onPageChange(totalPages)}
							title={t('goToPage', { page: totalPages })}>
							{totalPages}
						</Page>
					)}

					<Page
						disabled={isNextDisable}
						onPageChange={() => onPageChange(actualPage + 1)}
						title={t('nextPage')}>
						<IconKeyboardArrowRight />
					</Page>
				</PagesList>
			)}
		</PagerContainer>
	);
};
