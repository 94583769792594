import { useRef } from 'react';

import { useDateSegment } from '@react-aria/datepicker';
import { DateFieldState, DateSegment as DateSegmentAria } from '@react-stately/datepicker';

import { styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

import { Text } from '../../Text';

const Segment = styled('div', {
	base: {
		height: 'fit-content',
		paddingBlock: '2',
		paddingInline: '2',
		textAlign: 'center',
		outline: 'none',
		rounded: 'md',
		_focus: {
			backgroundColor: 'brand.80'
		}
	}
});

type SegmentProps = StyledVariantProps<typeof Segment>;
type DateSegmentProps = SegmentProps & {
	segment: DateSegmentAria;
	state: DateFieldState;
};

export const DateSegment = ({ segment, state }: DateSegmentProps) => {
	const ref = useRef(null!);
	const { segmentProps } = useDateSegment(segment, state, ref);

	if (segment.type === 'literal') {
		return (
			<span {...segmentProps} ref={ref}>
				{segment.text}
			</span>
		);
	}

	return (
		<Segment {...segmentProps} ref={ref}>
			<Text size="body.small">
				{segment.isPlaceholder ? segment.placeholder.toUpperCase() : segment.text}
			</Text>
		</Segment>
	);
};
