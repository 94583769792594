import { Flex, Stack } from '@agentero/styles/jsx';

import { Button } from '../Button';
import { CheckboxGroup } from '../inputs/CheckboxGroup';
import { Option } from '../inputs/shared/Option';

type MultipleCheckboxFilterProps<T extends string = string> = {
	label: string;
	value: T[];
	options: Option[];
	isClearable?: boolean;
	onChange: (values: T[]) => void;
};

export const MultipleCheckboxFilter = <T extends string = string>({
	label,
	value,
	options,
	isClearable = true,
	onChange
}: MultipleCheckboxFilterProps<T>) => (
	<Stack>
		<CheckboxGroup.Root
			label={''}
			aria-label={label}
			value={value || null}
			onChange={onChange as (values: string[]) => void}>
			{options.map(({ value, label }) => (
				<CheckboxGroup.Item key={value} value={value}>
					{label}
				</CheckboxGroup.Item>
			))}
		</CheckboxGroup.Root>
		{isClearable && (
			<Flex>
				<Button variant="link" onClick={() => onChange([])}>
					Clear filter
				</Button>
			</Flex>
		)}
	</Stack>
);
