import { BaseSearch } from '../../../DataTableFilters';

export const getTotalFiltersCount = <Search extends BaseSearch>(search: Search): number => {
	return Object.entries(search)
		.filter(([key, value]) => key !== 'searchText' && value !== undefined && value !== '')
		.reduce((acc: number, [, value]) => {
			if (Array.isArray(value)) {
				return acc + (value.length > 0 ? 1 : 0);
			}
			if (typeof value === 'object' && value !== null) {
				return acc + (Object.values(value).some(v => v !== undefined && v !== '') ? 1 : 0);
			}
			return acc + 1;
		}, 0);
};
