import { ReactElement, RefObject, cloneElement, useRef } from 'react';

import { useButton } from '@react-aria/button';

import { useAgModal } from '../Modal';

type TriggerProps = {
	children: ReactElement;
};

export const Trigger = ({ children }: TriggerProps) => {
	const { triggerProps } = useAgModal();
	const ref = useRef<HTMLButtonElement>(null);
	const { buttonProps } = useButton(triggerProps, ref as RefObject<HTMLButtonElement>);

	return <>{cloneElement(children, buttonProps)}</>;
};
